'use strict';

/**
 * Print Page
 * Takes an element and prints page from it
 */
var printPageTriggers = document.querySelectorAll('[data-print-page]');

var printPrintFriendlyPage = function printPrintFriendlyPage(e) {
    e.preventDefault();

    var printFriendlyUrl = e.target.getAttribute('data-print-page'),
        frameId = 'print-friendly-product-page',
        iframeEl = document.getElementById(frameId) ? document.getElementById(frameId) : null;

    if (!iframeEl) {
        iframeEl = document.createElement('iframe');
        iframeEl.id = frameId;
        iframeEl.name = frameId;
        iframeEl.style.height = '1px';
        iframeEl.style.visibility = 'hidden';
        document.body.appendChild(iframeEl);
        iframeEl.src = printFriendlyUrl;
    } else {
        // onload has occured, so use focus
        window.frames[frameId].focus();
        window.frames[frameId].print();
        window.frames[0].focus();
    }

    return false;
}

var addEventListenersPrintPageTriggers = function() {
    [].forEach.call(printPageTriggers, function(trigger) {
        trigger.addEventListener('click', printPrintFriendlyPage);
    });
}

var removeEventListenersPrintPageTriggers = function() {
    [].forEach.call(printPageTriggers, function(trigger) {
        trigger.removeEventListener('click', printPrintFriendlyPage)
    });
}

var init = function() {
    if (printPageTriggers.length > 0) {
        addEventListenersPrintPageTriggers();
    }
};

var destroy = function() {
    if (printPageTriggers.length > 0) {
        removeEventListenersPrintPageTriggers();
    }
}

module.exports = {
    init: init,
    destroy: destroy
}
