'use strict';

var eventClose = function(e) {
    $('#wishlistEnhancementsModalCreateList #newProductListName')
        .val('')
        .removeClass('is-invalid')
        .next('.invalid-feedback')
        .text('')
        .removeClass('d-block');
    $('.product-list-enhancements-create-list-form .create')
        .removeAttr('disabled');
};

var eventKeyupName = function(e) {
    var $this = $(this);
    var $invalidFeedback = $this.next('.invalid-feedback');
    var $submitButton = $('.product-list-enhancements-create-list-form .create');
    var name = $this.val().replace(/[^A-Za-z0-9]/g, '').toLowerCase();
    var listNames = $('#wishlistEnhancementsModalCreateList').data().listNames;
    if (listNames && listNames.indexOf(name) !== -1) {
        $submitButton.attr('disabled', 'disabled');
        $invalidFeedback.text($this.data().errorDuplicateName).addClass('d-block');
        $this.addClass('is-invalid');
    } else {
        $submitButton.removeAttr('disabled');
        $invalidFeedback.text('').removeClass('d-block');
        $this.removeClass('is-invalid');
    }
};

var eventSubmitCreate = function(e) {
    e.preventDefault();
    var $this = $(this);
    var $modal = $('#wishlistEnhancementsModalCreateList');
    var $productListNameElement = $this.find('#newProductListName');
    var productListName = $productListNameElement.val();
    var paginationData = $('.product-list-enhancements-landing-pagination').data();
    if (productListName && productListName !== '') {
        $productListNameElement.removeClass('is-invalid');
        $.spinner().start();
        $.ajax({
            url: $this.data().url,
            method: 'POST',
            dataType: 'html',
            data: {
                name: productListName,
                view: 'landing',
                paginationPage: (paginationData && paginationData.page) ? paginationData.page : 1
            },
            success: function(html) {
                var $html = $(html);

                $('.product-list-enhancements-global-error-display').trigger('clear');
                $('.product-list-enhancements-landing').trigger('updateLists', {
                    $lists: $html.find('.lists'),
                    $listsPagination: $html.find('.product-list-enhancements-landing-pagination')
                });
                $modal.modal('hide');
                $productListNameElement.val('');
            },
            error: function() {
                $modal.modal('hide');
                $productListNameElement.val('');
                $('.product-list-enhancements-global-error-display').trigger('error');
                $.spinner().stop();
            }
        });
        $('#wishlistEnhancementsModalCreateList').modal('hide');
    } else {
        $productListNameElement.addClass('is-invalid');
    }

};

var addEventListeners = function() {
    $('#wishlistEnhancementsModalCreateList')
        .on('submit', '.product-list-enhancements-create-list-form', eventSubmitCreate)
        .on('keyup', '#newProductListName', eventKeyupName)
        .on('hidden.bs.modal', eventClose);
};

var removeEventListeners = function() {
    $('#wishlistEnhancementsModalCreateList')
        .off('submit', '.product-list-enhancements-create-list-form', eventSubmitCreate)
        .off('keyup', '#newProductListName', eventKeyupName)
        .off('hidden.bs.modal', eventClose);
};

var init = function() {
    addEventListeners();
}

var destroy = function() {
    removeEventListeners();
}

module.exports = {
    init,
    destroy
}
