
'use strict';


var formErrors = require('core/checkout/formErrors');
var formValidation = require('base/components/formValidation');
var cart = require('../cart/cart');
var recaptcha = require('core/components/recaptcha');

const core = require('core/giftCertificate/giftCertificate');

function giftCertificateFormSubmission() {
    $('body').on('submit', 'form.GiftCertificateForm', function (e) {
        e.preventDefault();

        var form = $(this);
        var isEdit = false;
        var button = form.find('.btn-add-giftcertificate-tocart');
        if (!button || !button.length) {
            button = form.find('.btn-update-giftcertificate-incart');
            isEdit = true;
        } else {
            $('body').trigger('product:beforeAddToCart', this);
        }
        form.parents('.card').spinner().start();

        var url = form.attr('action');
        var queryString = form.serialize() + '&format=ajax';
        if (isEdit) {
            queryString += '&uuid=' + button.attr('data-uuid');
        }

        button.attr('disabled', true);
        recaptcha.check(e, {
            url: url,
            method: 'POST',
            dataType: 'json',
            data: queryString,
            success: function (data) {
                if (data.success) {
                    if (isEdit) {
                        $('#editGiftCertificateLineItemModal').modal('hide');
                        cart.updateCartTotals(data.cartModel);
                        updateGiftCertificateLineItem(data.cartModel, button.data('uuid'));
                        $('body').trigger('cart:update', [data, button.data('uuid')]);
                    } else {
                        $('body').trigger('product:afterAddToCart', data);
                    }
                    form.trigger('reset');
                    formErrors.clearPreviousErrors(form);
                } else {
                    if (data.redirectUrl) {
                        window.location.href = data.redirectUrl;
                    } else {
                        formValidation(form, data);
                    }
                }

                core.displayMessage(data, button);
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                core.displayMessage(err, button);
            }
        });
    })
};


function updateGiftCertificateLineItem(data, uuid) {
    var lineItem;
    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem) {
        $('.dwfrm_giftCertificate_purchase_from-' + uuid + ' span').html(lineItem.senderName);
        $('.dwfrm_giftCertificate_purchase_from-' + uuid).data('value', lineItem.senderName);
        $('.dwfrm_giftCertificate_purchase_senderEmail-' + uuid + ' span').html(lineItem.senderEmail);
        $('.dwfrm_giftCertificate_purchase_senderEmail-' + uuid).data('value', lineItem.senderEmail);
        $('.dwfrm_giftCertificate_purchase_recipient-' + uuid + ' span').html(lineItem.recipientName);
        $('.dwfrm_giftCertificate_purchase_recipient-' + uuid).data('value', lineItem.recipientName);
        $('.dwfrm_giftCertificate_purchase_recipientEmail-' + uuid).html(lineItem.recipientEmail);
        $('.dwfrm_giftCertificate_purchase_recipientEmail-' + uuid).data('value', lineItem.recipientEmail);
        $('.dwfrm_giftCertificate_purchase_message-' + uuid + ' span').html(lineItem.message || '');
        $('.dwfrm_giftCertificate_purchase_message-' + uuid).attr('title', lineItem.message || '');
        $('.dwfrm_giftCertificate_purchase_sendDate-' + uuid).html(lineItem.sendDate.substring(0, 10));
        $('.dwfrm_giftCertificate_purchase_sendDate-' + uuid).data('value', lineItem.sendDate.substring(0, 10));
        $('[data-gift-certificate-line-item-amount-' + uuid + ']').html(lineItem.priceTotal.price);
        $('[data-gift-certificate-line-item-amount-' + uuid + ']').data('value', lineItem.priceTotal.priceValue + '');
    }
}

function applyGiftCertificateToBasket () {
    $('body').on('click', '.giftcertificate-information .js-applybalancebutton', function (e) {
        e.preventDefault();

        var button = $(this);
        button.attr('disabled', true);
        var container = button.parents('.giftcertificate-information');
        var giftCertificateForm = button.parents('form');
        var isCartPage = button.closest('.cart-page').length > 0 ? true : false;

        container.spinner().start();

        var url = button.data('action');

        //because the error is not an html5 error typically (balance is 0 or does not exist)
        //we must clear the previous error + message manually
        formErrors.clearPreviousErrors(container);
        recaptcha.check(e, {
            url: url,
            method: 'POST',
            data: giftCertificateForm.serialize() + '&format=ajax',
            success: function (data) {
                if (data.success) {
                    core.displayMessage(data, button);
                    $('.js-giftcertificatepaymentinstruments').html(data.template);

                    if (isCartPage) {
                        // trigger cart event
                        $('body').trigger('cart:updateTotalAfterGiftCertificateAction', data);
                    } else {
                        // trigger checkout event
                        $('body').trigger('checkout:updateCheckoutViewPaymentInformation', { order: data.order });
                    }
                } else {
                    if (data.redirectUrl) {
                        window.location.href = data.redirectUrl;
                    } else {
                        container.find('.js-balancemsg').html('');
                        formValidation(container, data);
                        if (data.msg) {
                            core.displayMessage(data, button);
                        }
                    }
                }

                $.spinner().stop();
                button.removeAttr('disabled');
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                core.displayMessage(err, button);
            }
        });

    })
}

function removeGiftCertificatePaymentInstrument() {
    $('body').on('click', '.js-remove-giftcertificate-pi', function (e) {
        e.preventDefault();

        var button = $(this);
        button.attr('disabled', true);

        var container = button.parents('.giftcertificate-information');
        container.spinner().start();

        var url = button.data('action');
        var giftCertificateCode = button.data('giftcertificatecode');
        var isCartPage = button.closest('.cart-page').length > 0 ? true : false;

        $.ajax({
            url: url,
            method: 'POST',
            dataType: 'json',
            data: '&giftCertificateCode=' + giftCertificateCode,
            success: function (data) {
                if (data.success) {
                    $('.js-giftcertificatepaymentinstruments').html(data.template);
                    $('#giftcertificateid').val('');
                    if (isCartPage) {
                        // trigger cart event
                        $('body').trigger('cart:updateTotalAfterGiftCertificateAction', data);
                    } else {
                        // trigger checkout event
                        $('body').trigger('checkout:updateCheckoutViewPaymentInformation', { order: data.order });
                    }
                }

                $.spinner().stop();
                core.displayMessage(data, button);
            },
            error: function (err) {
                core.displayMessage(err, button);
            }
        });
    });
}

function checkBalanceFormSubmission() {
    $('body').on('submit', 'form.check-balance', function (e) {
        e.preventDefault();

        var form = $(this);
        form.spinner().start();

        var button = form.find('.js-checkbalancebutton');
        var url = form.attr('action');
        var queryString = form.serialize() + '&format=ajax';
        button.attr('disabled', true);

        //because the error is not an html5 error typically (balance is 0 or does not exist)
        //we must clear the previous error + message manually
        formErrors.clearPreviousErrors(form);
        recaptcha.check(e, {
            url: url,
            method: 'POST',
            dataType: 'json',
            data: queryString,
            success: function (data) {
                if (data.success) {
                    form.find('.balancemsg').html(data.balance).parents('.form-row').removeClass('d-none');
                } else {
                    form.find('.balancemsg').html('').parents('.form-row').addClass('d-none');
                    formValidation(form, data);
                    if (data.msg) {
                        displayMessage(data, button);
                    }
                }

                $.spinner().stop();
                button.removeAttr('disabled');
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                displayMessage(err, button);
            }
        });
    });
}

$('.btn-add-giftcertificate-tocart').on('click', function(){
    var offset = ($(window).width() < 767) ? 100 : 170;

    setTimeout(() => {
        if( $('.form-control.is-invalid').length > 0 ) {
            $('html, body').animate({ scrollTop: ( $("#amount").offset().top - offset ) }, 500);
        }
    }, 100);
});

$('#editGiftCertificateLineItemModal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget), recipient = button.data('uuid');
    $('.btn-update-giftcertificate-incart').attr('data-uuid', recipient);
  })

core.updateGiftCertificateLineItem = updateGiftCertificateLineItem;
core.giftCertificateFormSubmission = giftCertificateFormSubmission;
core.applyGiftCertificateToBasket = applyGiftCertificateToBasket;
core.removeGiftCertificatePaymentInstrument = removeGiftCertificatePaymentInstrument;
core.checkBalanceFormSubmission = checkBalanceFormSubmission;

core.init = function () {
    core.giftCertificateFormSubmission();
    core.checkBalanceFormSubmission();
    core.applyGiftCertificateToBasket();
    core.removeGiftCertificatePaymentInstrument();
}

module.exports = core;
