'use strict';
const formValidation = require('../components/formValidation');
const recaptcha = require('core/components/recaptcha');
const scheduler = require('./schedule');
const homepage = require('./homepage');

const submitAppointment = () => {
    $('body').on('submit', 'form.designBar-form', function (e) {
        e.preventDefault();

        const $form = $(this);
        const $userInfocard = $('.card.your-info');
        const url = $form.attr('action');
        $userInfocard.spinner().start();

        recaptcha.check(e, {
            url: url,
            type: 'post',
            data: $form.serialize(),
            success: function (data) {
                if (!data.success) {
                    formValidation($form, data);
                } else {
                    location.href = data.redirectUrl;
                }
            },
            error: function (err) {
                createErrorNotification(err.message);
                $userInfocard.spinner().stop();
            }
        });
        return false;
    });
}

const createErrorNotification = (message) => {
    var errorHtml = '<div class="alert alert-danger alert-dismissible  border--red' + 'fade show" role="alert">' + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' + '<span aria-hidden="true">&times;</span>' + '</button>' + message + '</div>';
    $('.error').append(errorHtml);
};

const showChangeCancelUI = () => {
    $('body').on('click', '.change-cancel-link', () => {
        $('.designBar-confirmation .confirmation-section').addClass('d-none');
        $('.designBar-confirmation .change-cancel').removeClass('d-none').addClass('d-flex');
    });
};

const cancelAppointment = () => {
    $('body').on('click', '.cancel-btn', (e) => {
        recaptcha.check(e, {
            url: $(e.currentTarget).attr('data-cancel-url'),
            type: 'get',
            success: function (data) {
                if (data.Success) {
                    // hide cancel modal, show success modal
                    $('#cancelAppointmentModal').modal('hide');
                    $('#cancelSuccessModal').modal('show');
                }
            },
            error: function (err) {
                createErrorNotification(err.message)
            }
        });
    })

    // when user closes success modal, redirect to main design services page
    $('body').on('hidden.bs.modal', '#cancelSuccessModal', function (e) {
        $('.designBar-confirmation').spinner().start();
        window.location.href = $(e.currentTarget).attr('data-redirect-url');
    })

}

const init = () => {
    scheduler.init(); // create scheduler
    submitAppointment(); // listen for appointment submission
    showChangeCancelUI(); // hide confirmation info, and show change cancel buttons
    cancelAppointment(); // cancels appt, and shows success modal

    homepage();
};

module.exports = init;
