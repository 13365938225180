'use strict';

const init = () => {

    $('body').on('mouseenter mouseleave', '.color-attribute[data-fabric-popover-enabled="true"]', (e) => {
        const $target = $(e.target);
        if (window.isMobile() || $target.parents().hasClass('product-quick-add-to-cart')) return; // popover only for PDP desktop

        const popoverAction = e.type === 'mouseleave' ? 'hide' : 'show';
        const $fabric = $target.is('span.swatch') ? $target.parent() : $target; // prevent child swatch from triggering hover

        if ($fabric.attr('data-popover-added') != 'true' && popoverAction === 'show') {
            const colorValue = $fabric.attr('data-color-value');
            const fabricPopoverConent = $(`[data-fabric-popover-content=${colorValue}]`).html();

            $fabric.popover({
                trigger: 'manual',
                placement: 'left',
                boundary: 'window',
                html: true,
                selector: '.color-attribute',
                content: fabricPopoverConent,
                template: '<div class="popover fabric-description" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body fabric-description-body"></div></div>' // content is injected into popover-body defined in this template
            });

            $fabric.attr('data-popover-added', 'true');
        }

        $fabric.popover(popoverAction);
    });
};

module.exports = { init };