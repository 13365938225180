'use strict';

var productListEnhancementsHelpers = require('./helpers.js');
const toastRecycleTime = 500;

var dismiss = function($toast) {
    $toast
        .removeClass('show')
        .attr('tabindex', '-1');
    if ($toast.data().productId) {
        $('[data-wishlistpid=' + $toast.data().productId + ']')
            .first()
            .trigger('focus');
    }
    removeEventListenersWindow();
};

var show = function($toast) {
    $toast
        .addClass('show')
        .attr('tabindex', '0')
        .trigger('focus');
    addEventListenersWindow();
};

var eventClickUndo = function(e) {
    var $this = $(this);
    var $toast = $this.parents('.product-list-enhancements-toast');
    var url;
    var productListId;
    var productListQuantities;
    var productOptions;

    if (typeof $toast.data().productListId === 'string') {
        url = $this.data().urlList;
        productListId = $toast.data().productListId;
        productListQuantities = $toast.data().productListQuantities;
        productOptions = $toast.data().productOptions;
    } else {
        url = $this.data().urlLists;
        productListId = $toast.data().productListId.join('|');
        productListQuantities = $toast.data().productListQuantities.join('|');
        productOptions = $toast.data().productOptions;
    }

    $.ajax({
        url: url,
        method: 'POST',
        dataType: 'json',
        data: {
            pid: $toast.data().productId,
            productListId: productListId,
            productListQuantities: productListQuantities,
            productOptions: productOptions
        },
        success: function(data) {
            if (data.success) {
                productListEnhancementsHelpers.updateUncachedData({
                    actionType: data.productListActionType,
                    pid: data.pid
                });
                productListEnhancementsHelpers.updateLinkData();
                productListEnhancementsHelpers.openToast(data);
            }
        }
    });
};

var eventClickWindow = function(e) {
    if (!$(e.target).is('[data-toast-trigger]')) {
        dismiss($('.product-list-enhancements-toast'));
    }
};

var eventKeyPressEscapeWindow = function (e) {
    if (e.key && e.key === 'Escape') {
        dismiss($('.product-list-enhancements-toast'));
    }
};

var eventStopPropagation = function(e) {
    e.stopPropagation();
};

var eventDismissButton = function(e) {
    e.preventDefault();
    dismiss($(this).parents('.product-list-enhancements-toast'));
}

var eventShow = function(e, callback) {
    e.preventDefault();
    var $toast = $(this);
    if ($toast.hasClass('show')) {
        dismiss($toast);
        setTimeout(() => {
            if (callback) {
                callback($toast, 33);
            }
            show($toast);
        }, toastRecycleTime);
    } else {
        if (callback) {
            callback($toast);
        }
        show($toast);
    }
};

var eventDismiss = function(e, callback) {
    e.preventDefault();
    var $toast = $(this);
    dismiss($toast);
    if (callback) {
        callback($toast);
    }
};

var addEventListenersToast = function() {
    $('.product-list-enhancements-toast')
        .on('click', eventStopPropagation)
        .on('click', '.undo', eventClickUndo)
        .on('click', '[data-toast-dismiss]', eventDismissButton)
        .on('show', eventShow)
        .on('dismiss', eventDismiss);
};

var removeEventListenersToast = function() {
    $('.product-list-enhancements-toast')
        .off('click', '.undo', eventClickUndo)
        .off('click', eventStopPropagation)
        .off('click', '[data-toast-dismiss]', eventDismissButton)
        .off('show', eventShow)
        .off('dismiss', eventDismiss);
};

var addEventListenersWindow = function() {
    $(window)
        .on('keydown', eventKeyPressEscapeWindow)
        .on('click', eventClickWindow);

};

var removeEventListenersWindow = function() {
    $(window)
        .off('keydown', eventKeyPressEscapeWindow)
        .off('click', eventClickWindow);
};

var init = function() {
    addEventListenersToast();
}

var destroy = function() {
    removeEventListenersToast();
    removeEventListenersWindow();
}

module.exports = {
    init,
    destroy
}
