const integrations = require('integrations/product/base');
const cart = require('../cart/cart');
const productListEnhancementsHelpers = require('../productListEnhancements/helpers.js');

// handle any cart updates that need to be made, if the product slider is on the cart page
function updateCartData(data) {
    if(data && data.cart) {
        if($('.card .product-info.uuid-' + data.pliUUID).length > 0) {
            cart.updateCartTotals(data.cart);
            cart.updateApproachingDiscounts(data.cart.approachingDiscounts);
            cart.updateAvailability(data.cart, data.pliUUID);
            $('body').trigger('cart:update');
        } else {
            /* We have to refresh the page. the inner content of shipment cards contains page-includes for
                content assets and the delivery options. These cannot be updated via ajax without creating specific endpoints for the content and re-registering all underlying listeners. It is much simpler and cleaner to simply refresh the cart page when we have a new shipment */
            location.reload();
        }
    }
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
 function handlePostCartAdd(response) {
    // had to write conditional for repsonse as it was failing when called on page load
    if (response) {
        $('.minicart').trigger('count:update', response);

        var messageType = response.error ? 'alert-danger' : 'alert-success';

        if ($('.add-to-cart-messages').length === 0) {
            $('body').append(
                '<div class="add-to-cart-messages"></div>'
            );
        }

        $('.add-to-cart-messages').append(
            '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
            + response.message
            + '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
}

function updateProductListEnhancementsButtons() {
    var $oldProductListEnhancementsIcon;

    $('body')
        .on('product:beforeAttributeSelect', function(e, attrData) {
            $oldProductListEnhancementsIcon = attrData.container.find('.primary-images .product-list-enhancements-toggle-product').first().clone(true);
        })
        .on('product:afterAttributeSelect', function(e, attrData) {
            if ($oldProductListEnhancementsIcon && $oldProductListEnhancementsIcon.length) {
                var $newProductListEnhancementsIcon = $oldProductListEnhancementsIcon;
                $newProductListEnhancementsIcon.attr('data-productlistenhancementspid', attrData.productlistenhancementspid);
                productListEnhancementsHelpers.updateLinkData($newProductListEnhancementsIcon);

                attrData.container.find('.primary-images .product-list-enhancements-toggle-product').replaceWith($newProductListEnhancementsIcon.clone(true));
            }
        });
}

integrations.processUpholsteryMaterial = () => {
    $('body')
    .on('product:beforeAttributeSelect', function (e, attrData) {

        const isColorSwatch = (attrData.selected && attrData.selected.hasClass('color-attribute'));
        if (!isColorSwatch) { // dont trigger display UI on color attribute select
            const $swatchButtons = $('.color-attribute');
            $swatchButtons.addClass('d-none');
        }

    })
    .on('product:afterAttributeSelect', function (e, attrData) {
        var varAttrs = attrData.data.product.variationAttributes;
        if (varAttrs != null && varAttrs.length > 0) {
            varAttrs.forEach(attr => {
                if (attr.id === 'upholsteryMaterial' || attr.id === 'color') {
                    attr.values.forEach(value => {

                        if (attr.id === 'upholsteryMaterial') {
                            $(`.upholsteryMaterial [data-attr-value="${value.id}"]`).attr('data-url', value.url); // only for PDP
                        }

                        if (attr.id === 'color' && value.available) {
                            // update swatch button
                            $(`[data-attr-value="${value.id}"]`)
                                .parent()
                                .removeClass('d-none')
                                .attr('data-fabric-popover-enabled', value.isFabricPopoverEnabled);
                        }
                    });
                }
            });
        }

        // update selected fabric
        $('span.selected-fabric')
            .empty()
            .html(attrData.data.product.selectedFabric.displayValue);
    });
};

integrations.processFabricDescription = () => {
    $('body')
        .on('product:afterAttributeSelect', (e, attrData) => {
            // update fabric popover content
            $('.fabric-popover-container')
                .empty()
                .html(attrData.data.renderedFabricPopoverContent);

            // re-initialize fabric popover
            require('../components/fabricPopover').init();

            // update fabric modal content
            $('.fabric-modal-container')
                .empty()
                .html(attrData.data.renderedFabricModals);

            // update Tabs content
            $('.tabs')
                .replaceWith(attrData.data.renderedTabsContent);

            // update Romance Description
            $('.row.mt-3.romance-description')
                .replaceWith(attrData.data.renderedProductRomanceDescription);

            // update Product Shipping Availability
            $('.row.mt-3.global-availability')
                .empty()
                .html(attrData.data.renderedProductShipping);

            // mobily only modal
            if (window.isMobile()) $(`#modal-${attrData.data.product.selectedFabric.id}`).modal('show');
        });
};

integrations.upholsteryMaterialSelect = () => {

    $(document).on('click', '.upholsteryMaterial .title', function (e) {
        e.preventDefault();

        const $this = $(this);
        const $currentUpholsteryMaterialCard = $this.closest('.card.upholsteryMaterial');

        if ($currentUpholsteryMaterialCard.hasClass('active') && !$this.hasClass('selected')) {
            $('.card.upholsteryMaterial').not($currentUpholsteryMaterialCard).removeClass('active'); // close all active cards, except currents

            // update font weights: only active container is semibold
            $this.removeClass('font-weight-normal');
            $this.addClass('font-weight-semibold selected');

            const $allTitlesExceptCurrent = $('.upholsteryMaterial .title').not($this);
            $allTitlesExceptCurrent.removeClass('font-weight-semibold selected');
            $allTitlesExceptCurrent.addClass('font-weight-normal');

            integrations.methods.attributeSelect($this.attr('data-url'), $this.closest('.product-detail'));
        } else if ($this.hasClass('selected') && !$currentUpholsteryMaterialCard.hasClass('active')) {
            $this.addClass('font-weight-normal');
            $this.removeClass('font-weight-semibold');
        } else if ($this.hasClass('selected')) {
            $this.addClass('font-weight-semibold');
            $this.removeClass('font-weight-normal');
        }
    });
};

 //Attributes that display as color swatches
integrations.colorAttribute = function () {
    $(document).on('click', '[data-attr="color"] button', function (e) {
        e.preventDefault();
        const $this = $(this)
        if (integrations.methods.checkForClickableAttribute($this)) {
            return;
        }
        var $productContainer = $this.closest('.set-item');
        if (!$productContainer.length) {
            $productContainer = $this.closest('.product-detail');
        }

        integrations.methods.attributeSelect($this.attr('data-url'), $productContainer, $this);

        $productContainer.find('.color-display-value').text($this.find('.swatch').data('displayvalue'));
    });
},

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 * @param {jQuery} $selectedAttribute - DOM element for current product
 */
integrations.methods.attributeSelect = function (selectedValueUrl, $productContainer, $selectedAttribute) {
    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect', {
            url: selectedValueUrl,
            container: $productContainer,
            selected: $selectedAttribute
        });

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                integrations.methods.handleVariantResponse(data, $productContainer);
                integrations.methods.updateOptions(data.product.optionsHtml, $productContainer);
                integrations.methods.updateQuantities(data.product.quantities, $productContainer);
                $('body').trigger('product:afterAttributeSelect', {
                    data: data,
                    container: $productContainer
                });
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

let superHandlePostCartAdd = integrations.methods.handlePostCartAdd;
integrations.methods.handlePostCartAdd = data => {
    if (data['error'] && 'error.cannot.add.product.quote' == data['code']) {
        var modal = $('#quotesModalAddToCartWithQuote');
        var url = data['url'];
        var form = data['form'];

        modal.find('button.btn-primary').off('click').on('click', () => {
            form['override'] = true;
            $.ajax({
                url: url,
                method: 'POST',
                data: form,
                success: _data => {
                    if (superHandlePostCartAdd) {
                        superHandlePostCartAdd(_data);
                    }
                    $('body').trigger('product:afterAddToCart', _data);
                    $('body').trigger('product:afterAddToCartQuickview', _data); //cart page quickview only
                    $.spinner().stop();
                    integrations.methods.miniCartReportingUrl(_data.reportingURL);
                }
            });
        });

        modal.modal('show');
    } else if (superHandlePostCartAdd) {
        superHandlePostCartAdd(data);
    }
}

function handleMinicartPopover(eventID, eventMessage) {
    var selector = "";
    if(eventID === undefined)
        selector = ".minicart-item:last-of-type";
    else
        selector = ".minicart-item.uuid-" + eventID;

    var url = $('.minicart').data('action-url');

    if ($('.minicart .popover.show').length === 0) {
        $.get(url, data => {
            $('.minicart .popover').empty();
            $('.minicart .popover').append(data);
        }).done(function() {
            if(eventMessage === "Product added to cart" && eventID === undefined) {
                $('p.d-none.text-center').removeClass('d-none');
            } else {
                $(selector).css("display", "block");
            }

            $('.minicart .popover').addClass('show forAddCart');
        });

        setTimeout(() => {
            if( $('.minicart .popover').hasClass('forAddCart') )
                $('.minicart .popover').removeClass('show forAddCart').empty();
        }, 4500);
    }
}

function autoClipSelectedCopies() {
    if(!$('.clamp_this').length)
        return;

    $(".clamp_this").each(function(){
        let maxLength = $(this).data('length');
        var myStr = $(this).text();
        if($.trim(myStr).length > maxLength){
            var newStr = myStr.substring(0, maxLength);
            var removedStr = myStr.substring(maxLength, $.trim(myStr).length);
            $(this).empty().html(newStr);
            $(this).append('<span class="read-more"> ... | <a class="text-decoration-underline">Read More</a></span>');
            $(this).append('<span class="more-text d-none">' + removedStr + ' <a class="read-less text-decoration-underline">Read Less</a></span>');
        }
    });

    $(".read-more").click(function(){
        $(this).siblings(".more-text").removeClass('d-none');
        $(this).addClass('d-none');
    });

    $(".read-less").click(function(){
        $(this).parents('.clamp_this').find('.read-more').removeClass('d-none');
        $(this).parent(".more-text").addClass('d-none');
    });
}

$(document).ready(function () {
    $('body').on('product:afterAddToCart', function(event, formData) {
        handleMinicartPopover(formData.pliUUID, formData.message);
    });

    $('body').on('product:afterAttributeSelect', function(event) {
        autoClipSelectedCopies();

        if( $(".quantity-select option:first").val() == 1 ) {
            $('.prices.top-prices').removeClass('per_item_badge');
        } else {
            $('.prices.top-prices').addClass('per_item_badge');
        }
    });

    $('body').on('slider:init.sliderEvents', (event, $sliderContainer) => {
        $( '.recommendations' ).each(function() {
            if( $(this).find('.slider-container').length && $(this).find('.slide').length <= 0 )
                $(this).addClass('d-none');
        });
    });

    autoClipSelectedCopies();
});

document.addEventListener("DOMContentLoaded", function(){
    if( $('select[name=select-size]').length && $('select[name=select-size]')[0].selectedIndex === 0 && $('.color-attribute').length ) {
        $.spinner().start();
        setTimeout(() => {
            $("select[name=select-size] option:eq(0)").prop("selected", false);
            $("select[name=select-size] option:eq(1)").prop("selected", "selected").change();
        }, 200);
    }
});

integrations.updateProductListEnhancementsButtons = updateProductListEnhancementsButtons;
integrations.customHandlePostCartAdd = handlePostCartAdd; // allow wizard products to trigger this without conflicting elsewhere

module.exports = integrations;
