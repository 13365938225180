'use strict';

const modalCreateList = require('./modalCreateList');
const modalEditList = require('./modalEditList');
const modalDeleteList = require('./modalDeleteList');
const cardList = require('./cardList');
const errorDisplay = require('./errorDisplay');

var updatePaginationPage = function(data) {
    $.spinner().start();
    $.ajax({
        url: data.url,
        method: 'POST',
        dataType: 'html',
        data: {
            paginationPage: data.paginationPage? data.paginationPage : 1
        },
        success: function(html) {
            var $html = $(html);

            $('.product-list-enhancements-global-error-display').trigger('clear');
            $('.product-list-enhancements-landing').trigger('updateLists', {
                $lists: $html.find('.lists'),
                $listsPagination: $html.find('.product-list-enhancements-landing-pagination')
            });
        },
        error: function() {
            $.spinner().stop();
            $('.product-list-enhancements-global-error-display').trigger('error');
        }
    });
};

var updateModalCreateListNames = function() {
    var listNames = $('.product-list-enhancements-landing .lists').data('listNames');
    var listNamesArray = [];

    if (listNames) {
        listNamesArray = listNames.split('|')
    }

    $('#productListEnhancementsModalCreateList, #productListEnhancementsModalEditList').data('listNames', listNamesArray);
};

var eventClickPaginationPage = function(e) {
    e.preventDefault();
    var $this = $(this);

    updatePaginationPage({
        url: $this.parents('.product-list-enhancements-landing-pagination').data().url,
        paginationPage: $this.data().paginationPage
    });
};

var eventUpdateLists = function(e, data) {
    var $landing = $('.product-list-enhancements-landing');
    var $pageinationSelectedButton = data.$listsPagination.find('.pagination-page[disabled]');

    $.spinner().start();
    cardList.destroy();
    $landing
        .find('.lists')
        .replaceWith(data.$lists);
    $landing
        .find('.product-list-enhancements-landing-pagination')
        .replaceWith(data.$listsPagination);

    if (data.$lists.data('listNames') === '') {
        $landing.find('.lists').addClass('d-none');
        $landing.find('.product-list-enhancements-message-empty').removeClass('d-none');
    } else {
        $landing.find('.lists').removeClass('d-none');
        $landing.find('.product-list-enhancements-message-empty').addClass('d-none');
    }

    if (data.$listsPagination.data().groupsCount === 1) {
        $landing.find('.product-list-enhancements-landing-pagination').addClass('d-none');
    } else {
        $landing.find('.product-list-enhancements-landing-pagination').removeClass('d-none');
    }

    if (
        $pageinationSelectedButton.length
        && $pageinationSelectedButton.data().url !== window.location.href
    ) {
        window.history.pushState(
            $pageinationSelectedButton.data().paginationPage,
            '',
            $pageinationSelectedButton.data().url
        );
    }

    cardList.init();
    updateModalCreateListNames();
    $.spinner().stop();
};

var eventOpenEditList = function(e, data) {
    $('#productListEnhancementsModalEditList')
        .attr({
            'data-product-list-id': data.productListId,
            'data-product-list-name': data.productListName
        })
        .data({
            productListId: data.productListId,
            productListName: data.productListName
        })
        .modal('show');
};

var eventOpenDeleteList = function(e, productListId) {
    $('#productListEnhancementsModalDeleteList')
        .attr('data-product-list-id', productListId)
        .data('productListId', productListId)
        .modal('show');
};

var eventPopstateWindow = function() {
    var urlSearchParams = new URLSearchParams(window.location.search);
    updatePaginationPage({
        url: $('.product-list-enhancements-landing .product-list-enhancements-landing-pagination').data().url,
        paginationPage: urlSearchParams.get('paginationPage')
    });
};

var addEventListeners = function() {
    $('.product-list-enhancements-landing')
        .on('click', '.pagination-page', eventClickPaginationPage)
        .on('openDeleteList', eventOpenDeleteList)
        .on('openEditList', eventOpenEditList)
        .on('updateLists', eventUpdateLists);

    $(window)
        .on('popstate', eventPopstateWindow);
};

var removeEventListeners = function() {
    $('.product-list-enhancements-landing')
        .off('click', '.pagination-page', eventClickPaginationPage)
        .off('openDeleteList', eventOpenDeleteList)
        .off('openEditList', eventOpenEditList)
        .off('updateLists', eventUpdateLists);

    $(window)
        .off('popstate', eventPopstateWindow);
};

var init = function() {
    if ($('.product-list-enhancements-landing').length) {
        addEventListeners();
        updateModalCreateListNames();
        modalCreateList.init();
        modalDeleteList.init();
        modalEditList.init();
        cardList.init();
        errorDisplay.init();
    }
}

var destroy = function() {
    removeEventListeners();
    modalCreateList.destroy();
    modalDeleteList.destroy();
    modalEditList.destroy();
    cardList.destroy();
    errorDisplay.destroy();
}

module.exports = {
    init,
    destroy
}
