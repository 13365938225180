'use strict';
/**
 * payment Controls
 * 
 * Binds New 'Update Address' and 'Add New' links to original 'Update Address' and 'Add new' Buttons
 */

let paymentControls = document.querySelectorAll('[data-payment-control]');

function toggleControl(e) {
    let target = e.target,
        parent = $(target).closest('[data-payment-form]')[0],
        buttonToControl = parent.querySelector( target.getAttribute('data-payment-control') );

    $(buttonToControl).trigger('click');
}

function init() {
    if (paymentControls.length > 0) {
        [].forEach.call(paymentControls, function(paymentControl) {
            paymentControl.addEventListener('click', toggleControl);
        });
    }    
}

module.exports = {
    init: init
};
