'use strict';

var eventClickUpdate = function(e) {
    e.preventDefault(e);
    var $this = $(this);
    var $modal = $('#quickViewModal');
    if (!$this.data().pidUpdated || $this.data().pidUpdated === $this.data().pid) {
        $modal.modal('hide');
    } else {
        $.spinner().start();
        $.ajax({
            url: $this.data().url,
            type: 'POST',
            data: {
                pid: $this.data().pid,
                pidUpdated: $this.data().pidUpdated,
                listId: $('.product-list-enhancements-detail').data().listId
            },
            dataType: 'html',
            success: function (html) {
                var $html = $(html);
                $('.product-list-enhancements-global-error-display').trigger('clear');
                $.spinner().stop();
                $('.product-list-enhancements-detail').trigger('updateProducts', $html.find('.product-list-enhancements-detail-products'));
                $modal.modal('hide');
            },
            error: function () {
                $('.product-list-enhancements-global-error-display').trigger('error');
                $modal.modal('hide');
                $.spinner().stop();
            }
        });
    }
};

var eventUpdateFromQuickView = function(e, payload) {
    var $updateButton = payload.container.find('.btn-update-product-list-enhancements');
    if (payload.data.product.masterId) {
        $updateButton
            .data('pid-updated', payload.data.product.id)
            .attr('data-pid-updated', payload.data.product.id)
            .removeAttr('disabled');
    } else {
        $updateButton
            .data('pid-updated', payload.data.product.id)
            .attr('data-pid-updated', payload.data.product.id)
            .attr('disabled', 'disabled');
    }
};

var addEventListeners = function() {
    $('body')
        .on('click', '.btn-update-product-list-enhancements', eventClickUpdate)
        .on('product:afterAttributeSelect', eventUpdateFromQuickView);
};

var removeEventListeners = function() {
    $('body')
        .off('click', '.btn-update-product-list-enhancements', eventClickUpdate)
        .off('product:afterAttributeSelect', eventUpdateFromQuickView);
};

var init = function() {
    addEventListeners();
};

var destroy = function() {
    removeEventListeners();
};

module.exports = {
    init,
    destroy
};
