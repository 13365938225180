'use strict';
const core = require('core/cart/cart');
const debounce = require('lodash/debounce');
const cartHelpers = require('./cartHelpers');
var siteIntegrations = require('integrations/integrations/siteIntegrationsUtils');
var toggleObject = siteIntegrations.getIntegrationSettings();
var base = require('core/product/base');

// this function was copied from core init
function initCouponUI() {
    $('.promo-code-form').submit(function (e) {
        e.preventDefault();

        var currentItemCount = parseInt($('.minicart-quantity').text(), 10) || 0;

        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        var $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();
        $('body').trigger('promotion:beforeUpdate');

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                    $('body').trigger('promotion:error', data);
                    $('.coupon-code-field').trigger('focus');
                    $.spinner().stop();
                } else {
                    // If cart item was added/removed via promo code submit
                    if (['', null, undefined].indexOf(data.numItems) && currentItemCount !== data.numItems) {

                        // Clean Url Structure of any erroneous parameters
                        if (window.history && ['', null, undefined].indexOf(data.actionUrls.showUrl) === -1) {
                            history.replaceState({}, null, data.actionUrls.showUrl);
                        }
                        // Force uncached reload
                        window.location.reload(true);
                    } else {
                        $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                        module.exports.updateCartTotals(data);
                        module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                        module.exports.validateBasket(data);
                        $('body').trigger('promotion:success', data);
                        $('.coupon-code-field').val('');
                        $.spinner().stop();
                    }
                }
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        var currentItemCount = parseInt($('.minicart-quantity').text(), 10) || 0;
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = module.exports.appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $('body').trigger('promotion:beforeUpdate');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();

                // If cart item was added/removed via promo code removal
                if (['', null, undefined].indexOf(data.numItems) && currentItemCount !== data.numItems) {
                    // Clean Url Structure of any erroneous parameters
                    if (window.history && ['', null, undefined].indexOf(data.actionUrls.showUrl) === -1) {
                        history.replaceState({}, null, data.actionUrls.showUrl);
                    }
                    // Force uncached reload
                    window.location.reload(true);
                } else {
                    module.exports.updateCartTotals(data);
                    module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                    module.exports.validateBasket(data);
                    $.spinner().stop();
                    $('body').trigger('promotion:success', data);
                }
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
}

function handleExpressTitle() {
    $(window).on('load', function(e) {
        var checkButtonInsertion = window.setInterval(function(){
            if( ($('.salesforce-paymentrequest-element').length && !$('.salesforce-paymentrequest-element').is(':empty')) || $('#cart-paypal-button-container').length ) {
                $('#express_title').removeClass('d-none');
                clearInterval(checkButtonInsertion);
            }
        }, 2000);

        setTimeout(() => { clearInterval(checkButtonInsertion); }, 10000);
    });
}

core.removeProductFromCart = function(config) {
    $('body > .modal-backdrop').remove();

    $('body').trigger('cart:beforeUpdate');

    $.spinner().start();
    $.ajax({
        url: config.url,
        type: 'get',
        dataType: 'json',
        success: function (data) {
            if (data.basket.items.length === 0) {
                location.reload();
            } else {
                if(data.basket.items.length === $('.gift_card_item').length)
                    $('.shipping-method').hide();

                if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                    for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                        $('.uuid-' + data.toBeDeletedUUIDs[i]).closest('.card').remove();
                    }
                }
                $('.uuid-' + config.uuid).closest('.card').remove();
                if (!data.basket.hasBonusProduct) {
                    $('.bonus-product').remove();
                }
                // NOTE: there's an open issue for problems with removing products with bonus items: https://github.com/SalesforceCommerceCloud/storefront-reference-architecture/issues/748
                $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                module.exports.updateCartTotals(data.basket);
                module.exports.updateApproachingDiscounts(data.basket.approachingDiscounts);
                $('body').trigger('setShippingMethodSelection', data.basket);
                module.exports.validateBasket(data.basket);

                $('body').trigger('cart:update', [data, config.uuid]);
                $.spinner().stop();
            }
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                module.exports.createErrorNotification(err.responseJSON.errorMessage);
                $.spinner().stop();
            }
        }
    });
}

/**
 * Listen for changes to "add assembly" checkboxes
 * if one is checked then they all should checked
 * once checked, hide options since customer will be required to use in-home delivery
 */
core.addAssemblyCheckboxListener = function() {
    var $cart = $('.cart');

    if($cart.length) {
        $cart.on('change', 'input[name^="add-assembly"]', function(e) {
            var productInfo = $(e.target).closest('.product-info').length ? $(e.target).closest('.product-info').get(0) : null,
                lineItemQtyEl = productInfo ? productInfo.querySelector('.quantity') : null,
                selectedOptionValueIds = cartHelpers.getAssemblyOptions(e.target),
                uuid = lineItemQtyEl ? lineItemQtyEl.getAttribute('data-uuid') : null,
                quantity = lineItemQtyEl ? lineItemQtyEl.value : null,
                pid = lineItemQtyEl ? lineItemQtyEl.getAttribute('data-pid') : null,
                updateProductUrl = e.target.value,
                formData = {
                    'selectedOptionValueIds' : selectedOptionValueIds,
                    'uuid' : uuid,
                    'pid' : pid,
                    'quantity' : quantity
                };

            $(this).parents('.card').spinner().start();
            $('body').trigger('cart:beforeUpdate');

            if (updateProductUrl) {
                $.ajax({
                    url: updateProductUrl,
                    type: 'post',
                    context: this,
                    data: formData,
                    dataType: 'json',
                    success: function (data) {

                        $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                        core.updateCartTotals(data.cartModel);
                        core.updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                        core.updateAvailability(data.cartModel, uuid);
                        core.updateProductDetails(data, uuid);

                        if (data.uuidToBeDeleted) {
                            $('.uuid-' + data.uuidToBeDeleted).remove();
                        }

                        core.validateBasket(data.cartModel);

                        $('body').trigger('cart:update', [data, uuid]);

                        $.spinner().stop();
                    },
                    error: function (err) {
                        if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        } else {
                            core.createErrorNotification(err.responseJSON.errorMessage);
                            $.spinner().stop();
                        }
                    }
                });
            }
        });
    }
}

core.updateShippingOptionList = function() {
    $('body').on('cart:beforeUpdate', function () {
        $('.totals').spinner().start();
        $('.shipping-method').spinner().start();
    });

    const getShipments = (data) => {
        if ( data.shipments ) {
            return data.shipments;
        } else if (data.basket && data.basket.shipments) {
            return data.basket.shipments;
        } else if (data.cartModel && data.cartModel.shipments) {
            return data.cartModel.shipments;
        }
        return null;
    };

    $('body').on('cart:update', function (e, data, uuid) {
        var shipments = getShipments(data);
        var shippingMethods = shipments[0].shippingMethods;
        var html = '';
        let showCostUI;
        var breakTag = '';
        var whiteGloveSurcharge;
        var shippingAmount;

        // get learn more modal html before removing shipping options
        const $learnMoreModalLink = $("[data-target='#WG_Optional_Cart']");
        let learnMoreLinkHtml = $learnMoreModalLink.length ? $($learnMoreModalLink[$learnMoreModalLink.length - 1]).wrap('<p/>').parent().html() : ''; // adding parent p tag to get complete element

        const shippingMethodText = shippingMethods.length > 1 ? 'Select Delivery Option:' : 'Delivery Method'
        const onlyOneShippingMethod = shippingMethods.length === 1 ? true : false;

        $('.shipping-options').empty();

        // recreate shipping options UI
        html += '<p class="shipping-method-header font-weight-semibold font_uppercase_letterspacing text-uppercase mb-0">' + shippingMethodText + '</p><div class="d-inline-flex"></span><div class="shipping-options-list">';

        shippingMethods.forEach(function (method) {
            //White Glove case
            if (/^whiteGlove/.test(method.ID) && data.totals && data.totals.whiteGloveSurcharge) {
                whiteGloveSurcharge = data.totals.whiteGloveSurcharge.numberValue;
            }

            const currencySymbol = method.shippingCost.charAt(0); // Extract the currency symbol (assuming it's the first character)
            showCostUI = '';
            if (method.showCost) {
                breakTag = '<br>';
                shippingAmount = whiteGloveSurcharge ? currencySymbol + ((parseFloat(method.shippingCost.replace(/[^0-9.]/g, '')) + whiteGloveSurcharge).toFixed(2)) : method.shippingCost;
                showCostUI = " <span class='font-weight-semibold shipping_cost'>" + shippingAmount + "</span>";
            }

            // if multiple shipping methods, use radio buttons
            let modalLink = method.learnMoreModal ? learnMoreLinkHtml : '';
            if(shippingMethods.length > 1){
                if(method.ID === "whiteGlove1" || method.ID === "whiteGlove2" || method.ID === "whiteGlove3") {
                    if (method.selected && whiteGloveSurcharge) {
                        html+= '<div class="shipping-option custom-control custom-radio mb-2"><input type="radio" class="custom-control-input" name="radio" id=' + method.ID + ' value=' + method.ID + ' data-checked=' + method.selected + '><label class="custom-control-label mb-2" for=' + method.ID + '><p class="shipping-method-text m-0"><span class="font-weight-semibold font_uppercase_letterspacing text-uppercase">' + method.displayName + '</span> - <span class="font-weight-semibold font_uppercase_letterspacing">' + currencySymbol + whiteGloveSurcharge + ' ' + $('.shipping-options').data('msg-short')  + '</span>' + breakTag + '<span class="d-inline"> ' + method.description + ' </span>' + modalLink + '</p></label></div>'
                    } else {
                        html+= '<div class="shipping-option custom-control custom-radio mb-2"><input type="radio" class="custom-control-input" name="radio" id=' + method.ID + ' value=' + method.ID + ' data-checked=' + method.selected + '><label class="custom-control-label mb-2" for=' + method.ID + '><p class="shipping-method-text m-0"><span class="font-weight-semibold font_uppercase_letterspacing text-uppercase">' + method.displayName + '</span> - <span class="font-weight-semibold font_uppercase_letterspacing"> ' + $('.shipping-options').data('msg-complete') + '</span>' + breakTag + '<span class="d-inline"> ' + method.description + ' </span>' + modalLink + '</p></label></div>';
                    }
                } else
                    html += '<div class="shipping-option custom-control custom-radio mb-2"><input type="radio" class="custom-control-input" name="radio" id=' + method.ID + ' value=' + method.ID + ' data-checked=' + method.selected + '><label class="custom-control-label mb-2" for=' + method.ID + '><p class="shipping-method-text m-0"><span class="font-weight-semibold font_uppercase_letterspacing text-uppercase">' + method.displayName + ' - ' + showCostUI + '</span>' + breakTag + '<span class="d-inline"> ' + method.description + ' </span>' + modalLink + '</p></label></div>'
            } else {
                html += '<div class="shipping-option custom-control custom-radio mb-2"><input type="hidden" class="" id=' + method.ID + ' value=' + method.ID + '><label class="custom-control-label mb-2" for=' + method.ID + '><p class="shipping-method-text m-0"><span class="font-weight-semibold font_uppercase_letterspacing text-uppercase">' + method.displayName + ' - ' + showCostUI + '</span>' + breakTag + '<span class="d-inline"> ' + method.description + ' </span>' + modalLink + '</p></label></div>'
            }
        })

        html + '</div></div>';

        $('.shipping-options').append(html);

        if (onlyOneShippingMethod) {
            $('.shipping-options').find('.shipping-options-list').addClass('one-shipping-method');
        } else {
            $('.shipping-options').find('.shipping-options-list').removeClass('one-shipping-method');
        }

        core.shippingOptionListener();
        core.updateCartTotals(data);
        core.shippingMethodHoverPopup();
        $.spinner().stop();
    });
}

core.shippingOptionListener = function() {

    $('.shipping-options [data-checked="true"]').prop('checked', true); // set initial checkbox state

    $('.shipping-options input[type=radio]').change(function (e) {
        var url = $('.shipping-options').attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).val()
        };

        $('.totals').spinner().start();
        $('body').trigger('cart:beforeShippingMethodSelected');
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    core.updateCartTotals(data);
                    core.updateApproachingDiscounts(data.approachingDiscounts);
                    core.validateBasket(data);
                }
                $('body').trigger('cart:shippingMethodSelected', data);
                $('body').trigger('cart:update', data);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    core.createErrorNotification(err.responseJSON.message);
                    $.spinner().stop();
                }
            }
        });
    });
}

const coreUpdateCartTotals = core.updateCartTotals;
core.updateCartTotals = (data) => {
    if (data.cartModel) {
        data = data.cartModel;
    } else if (data.basket) {
        data = data.basket;
    }

    coreUpdateCartTotals(data);

    if (data.totals.fragileSurcharge) {
        $('.fragile-surcharge span').empty().append(data.totals.fragileSurcharge);
    } else {
        $('.fragile-surcharge-container').remove();
    }

    if (data.totals.whiteGloveSurcharge && data.totals.whiteGloveSurcharge.numberValue) {
        $('.whiteglove-surcharge span').empty().append(data.totals.whiteGloveSurcharge.formatted); // Update the white Glove surcharge display
        $('.whiteglove-surcharge-container').removeClass('d-none'); // Ensure the container is visible
    } else {
        $('.whiteglove-surcharge-container').addClass('d-none'); // Hide the container if the surcharge is 0 or cannot be parsed
    }

    $('.subtotal span').empty().append(data.totals.subTotal);
    $('.shipping-cost span').empty().append(data.totals.shippingCost);

    if(data.totals.discounts.length > 0) {
        var discountLeadingLines = '<div class="row no-gutters order-discount"><div class="col start-lines"><p>Promo Applied:' + data.totals.discounts[0].couponCode + '</p></div><div class="col end-lines"><p class="text-right order-discount-total"><span>' + data.totals.discounts[0].price + '</span></p></div>';
        if($('.row.no-gutters.order-discount').length)
            $('.row.no-gutters.order-discount').replaceWith(discountLeadingLines);
        else
            $('.leading-lines.giftcertificate-discount').before(discountLeadingLines);
    } else {
        $('.row.order-discount').addClass('d-none');
    }

    if (toggleObject.AffirmOnline) {
        var totalCalculated = data.totals.grandTotal.substr(1).replace(/,/g, '');
        $('.affirm-as-low-as').attr('data-amount', (totalCalculated * 100).toFixed());
        var minLimit = $('.affirm-as-low-as').attr('data-limit-min');
        var maxLimit = $('.affirm-as-low-as').attr('data-limit-max');
        var isWithinAffirmLimit = (parseFloat(totalCalculated) >= parseFloat(minLimit) && parseFloat(totalCalculated) <= parseFloat(maxLimit))
        if (isWithinAffirmLimit) {
            $('#js-affirm-checkout-now').show();
        } else {
            $('#js-affirm-checkout-now').hide();
        $('.affirm-as-low-as').attr('data-amount', NaN);
        }

        if (typeof affirm.ui.refresh !== 'undefined') {
            affirm.ui.refresh();
        }
    }
}

core.updateSubtotalLabel = function() {
    $('body').on('cart:update', function (e, data) {
        if (data.basketHasAssemblyItems) {
            $('[data-add-assembly-message]').removeClass('d-none');
        } else {
            $('[data-add-assembly-message]').addClass('d-none');
        }
    });
}

core.stickyCheckoutButton = () => {
    function isElementInViewport(el) {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= $(window).height() &&
            rect.right <= $(window).width()
        );
    }

    const debounceScrollFn = debounce(function () {
        // when parent container is not in viewport, add sticky button styles
        if (!isElementInViewport($checkoutBtnContainer[0]) && !$checkoutBtnCard.hasClass('sticky-checkout-btn')) {
            $checkoutBtnCard.addClass('sticky-checkout-btn');
            //remove sitcky styles when parent container is in view
        } else if (isElementInViewport($checkoutBtnContainer[0]) && $checkoutBtnCard.hasClass('sticky-checkout-btn')) {
            $checkoutBtnCard.removeClass('sticky-checkout-btn');
        }
    }, 100);

    const $checkoutBtnContainer = $('.checkout-btn-container');
    const $checkoutBtnCard = $('.checkout-btn-card');

    if ($checkoutBtnContainer.length) {
        $(window).scroll(debounceScrollFn);
    }
}

core.shippingMethodHoverPopup = () => {
    const $whiteGloveShippingContainer =
        $('#whiteGlove3').length ? $('#whiteGlove3').parent() :
        ($('#whiteGlove2').length ? $('#whiteGlove2').parent() : $('#whiteGlove1').parent());

    $whiteGloveShippingContainer.unbind('mouseenter mouseleave'); // remove any previously bound events, if any

    const $learnMoreModal = $('#learnMoreModal');

    // if free delivery is checked, initiate popup hover
    if ($('#curbside-delivery').attr('data-checked') === 'true') {
        let popupInterval = null;
        let counter = 0;

        const addHoverInterval = () => {
            counter = 0;
            popupInterval = setInterval(() => {
                if(counter < 3) {
                    ++counter;
                } else {
                    removeHoverInterval();
                    $learnMoreModal.modal('show');
                }
            }, 1000)
        };

        const removeHoverInterval = () => {
            counter = 0;
            clearInterval(popupInterval)
        };

        // add hover listener to inpute parent container, bound mouseenter and mouseleave
        $whiteGloveShippingContainer
        .hover(addHoverInterval, removeHoverInterval);

        // turn on hover listeners when modal is finished being hidden
        $learnMoreModal.on('hidden.bs.modal', function (e) {
            $whiteGloveShippingContainer.hover(mouseEnter, mouseLeave);
        });

        // turn off hover listeners when when modal is showing
        $learnMoreModal.on('shown.bs.modal', (e) => {
            removeHoverInterval();
            $whiteGloveShippingContainer.unbind('mouseenter mouseleave');
        });

        // turn off hover
        $whiteGloveShippingContainer.on('click', (e) => {
            removeHoverInterval();
        });
    }
};

core.updateGiftCerticateCustomFields = () => {
     //responsible for filling edit gift cert modal with information from line item on cart page
     $('body').on('click', 'a.edit.editGiftCertificateLineItem', function (e) {
        e.preventDefault();

        var anchor = $(this);
        var uuid = anchor.data('uuid');
        var parentContainer = anchor.parents('.product-info.uuid-' + uuid);
        var senderEmail = parentContainer.find('.dwfrm_giftCertificate_purchase_senderEmail-' + uuid).data('value');
        var sendDate = parentContainer.find('.dwfrm_giftCertificate_purchase_sendDate-' + uuid).data('value');
        var amount = parentContainer.find('[data-gift-certificate-line-item-amount-' + uuid + ']').data('value');
        var from = parentContainer.find('.dwfrm_giftCertificate_purchase_from-' + uuid).data('value');
        var recipient = parentContainer.find('.dwfrm_giftCertificate_purchase_recipient-' + uuid).data('value');
        var recipientEmail = parentContainer.find('.dwfrm_giftCertificate_purchase_recipientEmail-' + uuid).data('value');
        var giftMessage = parentContainer.find('.dwfrm_giftCertificate_purchase_message-' + uuid).attr('title');

        var amountForNumberInput = parseFloat(amount.replace(/\$|,/g, ''));
        var modal = $('#editGiftCertificateLineItemModal');

        modal.find('#amount').attr('value', amountForNumberInput);
        modal.find('#sendDate').attr('value', cartHelpers.getFormattedDate(sendDate));
        modal.find('#from').attr('value', from);
        modal.find('#senderEmail').attr('value', senderEmail);
        modal.find('#recipient').attr('value', recipient);
        modal.find('#recipientEmail').attr('value', recipientEmail);
        modal.find('#message').text(giftMessage);

        modal.find('.form-control-label').addClass('form-floating-label-transform');

        cartHelpers.tiggerModalFormFocus(modal);

    });
}

core.updateTotalsAfterGiftCertificateAction = () => {
    $('body').on('cart:updateTotalAfterGiftCertificateAction', function(e, data) {
        var grandTotalSum = e.target.querySelector('.grand-total-sum');

        // update the 'Estimated Total' value
        if (grandTotalSum) {
            $(grandTotalSum).text(data.order.totals.grandTotalLessGiftCertificatePaymentInstrumentsFormatted);
        }

        // hide/show GC line item
        if (data.order.billing.payment.giftCertificatePaymentInstruments && data.order.billing.payment.giftCertificatePaymentInstruments.length) {
            $('.giftcertificate-discount').removeClass('d-none');
            $('.giftcertificate-discount-label').text(data.order.totals.giftCertificatePaymentInstrumentsLabel);
            $('.giftcertificate-discount-total').text('- ' + data.order.totals.giftCertificatePaymentInstrumentsTotalFormatted);
        } else {
            $('.giftcertificate-discount').addClass('d-none');
        }
    });
}

core.movetowishlist = () => {
    var productListEnhancementsHelpers = require('../productListEnhancements/helpers.js');
    $('body').on('click', '.cart-page .product-edit .add-to-wishlist', function (e) {
        e.preventDefault();
        var $this = $(this);
        var quantity = 1;
        var $quantitySelect = $('#quantity-' + $this.data().uuid);
        if ($quantitySelect.length) {
            quantity = $quantitySelect.find(":selected").val();
        }
        if (window.UncachedData.customer.isAuthenticated) {
            $('#productListEnhancementsModalShowLists')
                .data('pid', $this.data().pid)
                .data('cartUUID', $this.data().uuid)
                .data('quantity', quantity)
                .data('multiSelect', false)
                .data('removeFromCart', true)
                .data('action', 'cart')
                .modal('show');
        } else {
            $.ajax({
                url: $this.data().urlAddToWishlist,
                method: 'POST',
                dataType: 'json',
                data: {
                    pid: $this.data().pid,
                    productListQuantities: quantity
                },
                success: function(data) {
                    if (data.success) {
                        productListEnhancementsHelpers.updateUncachedData({
                            actionType: data.productListActionType,
                            pid: data.pid
                        });

                        productListEnhancementsHelpers.updateLinkData();

                        var urlParams = {
                            pid: $this.data().pid,
                            uuid: $this.data().uuid
                        };

                        core.removeProductFromCart({
                            url: module.exports.appendToUrl($this.data().urlRemoveFromCart, urlParams),
                            uuid: urlParams.uuid,
                            pid: urlParams.pid
                        });
                    }
                }
            });
        }
    });
}

core.init = () => {

    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');

        module.exports.confirmDelete(actionUrl, productID, productName, uuid);
    });

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        module.exports.confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = module.exports.appendToUrl(url, urlParams);

        core.removeProductFromCart({
            url: url,
            uuid: uuid,
            pid: productID
        });

    });

    $('body').on('change', '.quantity-form > .quantity', function () {
        var url = $(this).data('action');
        if (!url) {
            return;
        }

        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = core.appendToUrl(url, urlParams);

        $(this).parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                var $card = $('.card .uuid-' + uuid);

                var cardData = data.items.filter(function(obj) {
                    return (obj.UUID === uuid);
                })[0];

                if (cardData && cardData.priceTotal.nonAdjustedPrice) {
                    $card
                        .find('.list-price')
                        .html(cardData.priceTotal.nonAdjustedPrice);

                    $card
                        .find('.price .list .value')
                        .attr('content', cardData.priceTotal.nonAdjustedPrice);
                }

                if (cardData && cardData.priceTotal.price) {
                    $card
                        .find('.sales-price')
                        .html(cardData.priceTotal.price);

                    $card
                        .find('.price .sales .value')
                        .attr('content', cardData.priceTotal.price);
                }

                $card
                    .find('.quantity')
                    .val(quantity);

                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);

                core.updateCartTotals(data);
                core.updateApproachingDiscounts(data.approachingDiscounts);
                core.updateAvailability(data, uuid);
                core.validateBasket(data);

                $(this).data('pre-select-qty', quantity)
                $('body').trigger('cart:update', [data, uuid]);

                $.spinner().stop();

                if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                    location.reload();
                }
            },
            error: function (err) {
                window.console.log(err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    core.createErrorNotification(err.responseJSON.message);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('quantity', selectedQuantity);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();
        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var form = $(this).closest('.cart-and-ipay').find('.update-cart-url').data();
        if (form) {
            form.selectedOptionValueIds = base.methods.getOptions($('#quickViewModal'));
            form.pid = core.getPidValue($(this))
            $(this).parents('.card').spinner().start();
            $('body').trigger('cart:beforeUpdate');

            if (updateProductUrl) {
                $.ajax({
                    url: updateProductUrl,
                    type: 'post',
                    context: this,
                    data: form,
                    dataType: 'json',
                    success: function (data) {
                        $('#quickViewModal').modal('hide');

                        $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                        core.updateCartTotals(data.cartModel);
                        core.updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                        core.updateAvailability(data.cartModel, form.uuid);
                        core.updateProductDetails(data, form.uuid);

                        if (data.uuidToBeDeleted) {
                            $('.uuid-' + data.uuidToBeDeleted).remove();
                        }

                        core.validateBasket(data.cartModel);

                        $('body').trigger('cart:update', [data, form.uuid]);

                        $.spinner().stop();
                    },
                    error: function (err) {
                        if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        } else {
                            core.createErrorNotification(err.responseJSON.message);
                            $.spinner().stop();
                        }
                    }
                });
            }
        }
    });

    initCouponUI();
    handleExpressTitle();

    core.addAssemblyCheckboxListener();
    core.updateShippingOptionList();
    core.shippingOptionListener();
    core.stickyCheckoutButton();
    core.updateGiftCerticateCustomFields();
    core.updateTotalsAfterGiftCertificateAction();
    core.updateSubtotalLabel();
    core.movetowishlist();

    // integration point for collect.js cart tracking
    require('../salesforceServices/collect').cartUpdates();
};

module.exports = core;
