'use strict';
const recaptcha = require('core/components/recaptcha');
const moment = require('moment-timezone');
const debounce = require('lodash/debounce');
const floatingLabels = require('../components/floatingLabels');
const SiteConstants = require('constants/SiteConstants');
const clientSideValidation = require('../components/clientSideValidation');

const createTimezoneDropdown = () => {
    const $tzDropdown = $('.timezone-dropdown');
    if ($tzDropdown.length) {
        var tzOptionsHTML = '';
        const currentTimezone = moment.tz.guess();

        moment.tz.names().map(function (timezone) {
            // sanitize data: ignore timezone if only offsets provided
            if (!timezone.includes('+') || !timezone.includes('-')) {
                var select = currentTimezone === timezone ? 'selected' : '',
                    gmtOffset = moment.tz(timezone).format('Z');

                tzOptionsHTML += `<option class="font-size--12" ${select} value="${timezone}">(GMT${gmtOffset}) ${timezone}</option>`;
            }
        });

        $tzDropdown.append(tzOptionsHTML);
        $('body').trigger('designBar:timezone', [{
            'timezone': $tzDropdown.find(":selected").val()
        }]);

        // emits trigger when dropdown changes
        timezoneChangeListener();
    }
};

const timezoneChangeListener = () => {
    $('body').on('change', '.timezone-dropdown', (e) => {
        $('body').trigger('designBar:timezone', [{
            'timezone': $(e.currentTarget).find(':selected').val()
        }]);
    });
};

const createScheduler = () => {
    let $schedule = $('.schedule');
    let $chooseAppointment = $('.choose-appointment');

    $('body').on('designBar:timezone', (e, data) => {
        $chooseAppointment.spinner().start();

        let querystring = `timezone=${data.timezone}`;
        $schedule.empty();
        let url = $schedule.attr('data-get-schedule-url');

        recaptcha.check(e, {
            url: url,
            type: 'get',
            data: querystring,
            success: function (data) {
                $schedule.append(data);
                $chooseAppointment.spinner().stop();
                $('body').trigger('designBar:loadsuccess');
            },
            error: function (err) {
                createErrorNotification(err.responseJSON.message);
                $chooseAppointment.spinner().stop();
            }
        });
    });

    // create the timezone drop down, which triggers the creation of scheduler
    createTimezoneDropdown();

    // add functionality to scheduler nav
    schedulerNavigation();

    // add Listeners
    appointmentListener();
    schedulerScreenSizeWatch();
    continueButtonListener();
    editLinkListener();
};

const appointmentListener = () => {
    $('body').on('click', '.appointment', (e) => {
        let $appointment = $(e.target);

        if (!$appointment.hasClass('link-continue')) {
            let $activeAppt = $('.appointment.show');
            $activeAppt.removeClass('show');
            $activeAppt.find('.appointment-time').removeClass('font-weight-semibold');

            $appointment = $appointment.hasClass('appointment-time') ? $appointment.parent() : $appointment;
            $appointment.addClass('show');
            $appointment.find('.appointment-time').addClass('font-weight-semibold');
        }
    });
}

const schedulerScreenSizeWatch = () => {
    let iterator;
    let resetScheduler = false;

    $(window).on('resize', debounce(function (e) {
        const $scheduleNav = $('.schedule-nav');
        const $apptGroup = $('.appointment-group');
        if (e.currentTarget.innerWidth >= SiteConstants.BreakpointSizes.lg && $scheduleNav.attr('data-nav-iterator') != 7) {
            $scheduleNav.attr('data-nav-iterator', 7);
            iterator = 7;
            resetScheduler = true;
        } else if (e.currentTarget.innerWidth < SiteConstants.BreakpointSizes.lg && $scheduleNav.attr('data-nav-iterator') != 3) {
            iterator = 3;
            resetScheduler = true;
        }

        if (resetScheduler) {
            $apptGroup.addClass('d-none');
            $scheduleNav.attr('data-nav-iterator', iterator);
            $scheduleNav.attr('data-start-nav', 0);
            $scheduleNav.attr('data-end-nav', iterator);
            $scheduleNav.find('.show-previous').addClass('disabled');
            $apptGroup.slice(0, iterator).removeClass('d-none');
        }
    }, 50));
};

const schedulerNavigation = () => {
    $('body').on('designBar:loadsuccess', () => {
        const $showPrevious = $('.show-previous');
        const $showmore = $('.show-more');

        // remove any previous event listeners
        $showPrevious.off();
        $showmore.off();

        const $apptGroup = $('.appointment-group');
        const numGroups = $apptGroup.length; // use for error handling

        let iterator = $(window).width() < SiteConstants.BreakpointSizes.lg ? 3 : 7;

        const $scheduleNav = $('.schedule-nav').attr('data-nav-iterator', iterator);

        $apptGroup.slice(0, iterator).removeClass('d-none');

        $scheduleNav.attr('data-nav-iterator', iterator);
        $scheduleNav.attr('data-start-nav', 0);
        $scheduleNav.attr('data-end-nav', iterator);

        $showmore.click(function () {
            iterator = parseInt($scheduleNav.attr('data-nav-iterator'));

            // check screen size, what happens if in mobile and switch to desktop?? vice versa
            let startNav = parseInt($scheduleNav.attr('data-start-nav'));
            let endNav = parseInt($scheduleNav.attr('data-end-nav'));

            // hide current
            $apptGroup.slice(startNav, endNav).addClass('d-none');

            // update start count
            startNav = endNav;
            $scheduleNav.attr('data-start-nav', startNav);

            // update end count
            endNav = endNav + iterator;
            $scheduleNav.attr('data-end-nav', endNav);

            // show next group
            $apptGroup.slice(startNav, endNav).removeClass('d-none');

            startNav === 0 ?
                $showPrevious.addClass('disabled') :
                $showPrevious.removeClass('disabled');

            endNav >= numGroups ?
                $showmore.addClass('disabled') :
                $showmore.removeClass('disabled');
        });

        $showPrevious.click(function () {
            const iterator = parseInt($scheduleNav.attr('data-nav-iterator'));

            let startNav = parseInt($scheduleNav.attr('data-start-nav'));
            let endNav = parseInt($scheduleNav.attr('data-end-nav'));

            // hide current
            $apptGroup.slice(startNav, endNav).addClass('d-none');

            // update end count
            endNav = endNav - iterator;
            $scheduleNav.attr('data-end-nav', endNav);

            // update start count
            startNav = endNav - iterator;
            $scheduleNav.attr('data-start-nav', startNav);

            // show prev group
            $apptGroup.slice(startNav, endNav).removeClass('d-none');

            startNav === 0 ?
                $showPrevious.addClass('disabled') :
                $showPrevious.removeClass('disabled');

            endNav >= numGroups ?
                $showmore.addClass('disabled') :
                $showmore.removeClass('disabled');
        });
    });
}

const continueButtonListener = () => {
    $('body').on('click', '.link-continue', (e) => {
        const $currentTarget = $(e.currentTarget);

        const $chooseAppointment = $('.choose-appointment');
        const $userInfo = $('.your-info');

        $userInfo.spinner().start();

        const $reschedule = $('[data-reschedule]');

        let url = $currentTarget.attr('data-url');
        let actionType = 'get';
        
        if($reschedule.length) {
            const timeSlot = $currentTarget.attr('data-appointment-gmt');
            const formattedTime = $currentTarget.attr('data-confirmation-format-timedate');
            url = $reschedule.attr('data-reschedule') + `&timeSlot=${timeSlot}` + `&confirmationTimeFormatted=${formattedTime}`;
            actionType = 'post'
        }

        recaptcha.check(e, {
            url: url,
            type: actionType,
            success: function (data) {
                if ($reschedule.length) {
                    window.location.href = data.redirectUrl;
                } else {
                    // collapse choose appointment
                    $chooseAppointment
                        .removeClass('active')
                        .find('.selected-appointment')
                            .removeClass('d-none')
                            .addClass('d-flex')
                            .find('p.timedate')
                            .empty()
                            .append(`${$currentTarget.attr('data-format-timedate')} |`);

                    // append form data
                    $('.schedule-form')
                        .empty()
                        .append(data);

                    // required for client side validation to work properly on form
                    clientSideValidation.invalid();

                    floatingLabels.init();
                    $userInfo.addClass('active');
                    $userInfo.spinner().stop();

                    $('html, body').animate({
                        scrollTop: 0
                    }, 500, 'linear');
                }
            },
            error: function (err) {
                createErrorNotification(err.responseJSON.message);
                $userInfo.spinner().stop();
            }
        });
    });
}

const editLinkListener = () => {
    $('body').on('click', '.edit-link', (e) => {
        // collapse info card
        const $userInfo = $('.your-info');
        $userInfo.removeClass('active');

        // hide choose appointment updates
            $('.selected-appointment')
                .removeClass('d-flex')
                .addClass('d-none');

        // expand choose apopintment
        const $chooseAppointment = $('.choose-appointment');
        $chooseAppointment.addClass('active');
    });
}

const createErrorNotification = (message) => {
    var errorHtml = '<div class="alert alert-danger alert-dismissible border--red ' + 'fade show" role="alert">' + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' + '<span aria-hidden="true">&times;</span>' + '</button>' + message + '</div>';
    $('.error').append(errorHtml);
};

const init = () => {
    $((e) => {
        createScheduler();
    });
};

module.exports = {
    init
};