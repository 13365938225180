'use strict';

const debounce = require('lodash/debounce');

/**
 * This file gets the current category associated with page (if there is one).
 * Then it toggles that cateogrys menu item so that the sub-menu shows automatically.
 * If there is no cureent category associated with page, then a defualt is selected
 * fom the main nav.
 */

// default is first main top level category - presumable 'Home & OutDoor'
function getActiveCategory() {
    var topLevelNavLink = document.querySelector('[data-top-level-nav-link]'),
        activeTopLevelCategory = document.querySelector('[data-active-top-level-category]'),
        activeCat = null,
        defaultCategoryElement = null;

    if (activeTopLevelCategory) {
        activeCat = activeTopLevelCategory.getAttribute('data-active-top-level-category');
        defaultCategoryElement = document.querySelector('.navbar-nav > .nav-item #' + activeCat);
    } else {
        defaultCategoryElement = topLevelNavLink;
    }
    // double check for null on homepage
    if (defaultCategoryElement === null) {
        defaultCategoryElement = topLevelNavLink;
    }

    return defaultCategoryElement;
}

// updateMain Nav - Called once after page load
function updateMainNavCurrentClass(activeCategory) {
    var $activeCategory = $(activeCategory);
    if ($activeCategory && $activeCategory.hasClass('dropdown-link')) {
        $activeCategory
            .parents('.custom-dropdown')
            .find('.dropdown-toggle')
            .first()
            .addClass('current-category');
    } else if ($activeCategory) {
        $activeCategory
            .addClass('current-category');
    }
};

// 'dropdown-menu' classes need to be added to navigation when screen is at mobile size
// this ensures the panel toggling behavior that is OOTB AB
// however, for Desktop sizes, we need to remove the '.dropdown-menu' class on our
// 1st level sub-nav to ensure it will show properly under parent <ul> on desktop.
function toggleDropdownMenuClass($topLevelNav) {
    if (!window.isMobile() && $topLevelNav.length > 0) {
        $topLevelNav.find('.navbar-nav-sub').removeClass('dropdown-menu');
    } else {
        $topLevelNav.find('.navbar-nav-sub').addClass('dropdown-menu');
    }
}

function setUpToggleDropdowns($topLevelNav) {
    // once on load:
    toggleDropdownMenuClass($topLevelNav);
    // // then on resize
    $(window).on('resize', debounce(function() {
        toggleDropdownMenuClass($topLevelNav);
    }, 100));
}


/** Add current-category class on click */
function mainNavClickHandler() {
    var navBar = document.querySelector('.navbar-nav'),
        allNavItems = navBar ? navBar.querySelectorAll('.navbar-nav a') : null;

    if (allNavItems !== null && allNavItems.length > 0) {
        [].forEach.call(allNavItems, function(link) {
            link.addEventListener('click', function(e) {
                // get closest top level nav
                var closestTopLevelNavLink = $(e.target).closest('.navbar-nav > .nav-item');
                // remove the current-category class from the navigation
                navBar.querySelector('.current-category').classList.remove('current-category');
                // add the current-category class to the link under the top level nav li item
                closestTopLevelNavLink[0].querySelector('.nav-link').classList.add('current-category');
            });
        });
    }
}

/** Blog Categores & Sub Category helpers */
function updateActiveBlogCategories() {
    var activeIdsEl = document.querySelector('[data-active-ids]');

    if (activeIdsEl) {
        var activeIds = activeIdsEl.getAttribute('data-active-ids').split(',');

        [].forEach.call(activeIds, function(activeId) {
            var navItems = [document.querySelector('#' + activeId), document.querySelector('#blog-' + activeId)];

            [].forEach.call(navItems, function(navItem) {
                if (navItem) {
                    navItem.parentElement.classList.add('active');
                }
            });
        });
    }
}

function init() {
    var activeCategory = getActiveCategory(),
        $topLevelNav = $('.navbar-nav > .custom-dropdown');

    // toggle the current active top level nav link
    updateMainNavCurrentClass(activeCategory);

    // click handler for adding 'current category' class to menu
    mainNavClickHandler();

    // set up toggle Dropdowns & resize handler
    setUpToggleDropdowns($topLevelNav);

    // active Blog Cats
    updateActiveBlogCategories();
}

module.exports.init = init;
