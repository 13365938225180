'use strict';

var eventClickUpdate = function(e) {
    e.preventDefault(e);

    var $this = $(this);
    var $modal = $('#quickViewModal');

    var core = require('core/product/base');
    var form = {
        pid: $this.data().pid,
        pidUpdated: $this.data().pidUpdated,
        uuid: $this.data().uuid,
        listId: $('.product-list-enhancements-detail').data().listId,
        selectedOptionValueIds: core.methods.getOptions($modal)
    };

    $.spinner().start();
    $.ajax({
        url: $this.data().url,
        type: 'POST',
        data: form,
        dataType: 'html',
        success: function (html) {
            var $html = $(html);
            $('.product-list-enhancements-global-error-display').trigger('clear');
            $.spinner().stop();
            $('.product-list-enhancements-detail').trigger('updateProducts', $html.find('.product-list-enhancements-detail-products'));
            $modal.modal('hide');
        },
        error: function () {
            $('.product-list-enhancements-global-error-display').trigger('error');
            $modal.modal('hide');
            $.spinner().stop();
        }
    });
};

var eventUpdateFromQuickView = function(e, payload) {
    var $updateButton = payload.container.closest('.modal').find('.btn-update-product-list-enhancements');
    if (payload.data.product.masterId) {
        $updateButton
            .data('pid-updated', payload.data.product.id)
            .attr('data-pid-updated', payload.data.product.id)
            .removeAttr('disabled');
    } else {
        $updateButton
            .data('pid-updated', payload.data.product.id)
            .attr('data-pid-updated', payload.data.product.id)
    }
};

var addEventListeners = function() {
    $('body')
        .on('click', '.btn-update-product-list-enhancements', eventClickUpdate)
        .on('product:afterAttributeSelect', eventUpdateFromQuickView);
};

var removeEventListeners = function() {
    $('body')
        .off('click', '.btn-update-product-list-enhancements', eventClickUpdate)
        .off('product:afterAttributeSelect', eventUpdateFromQuickView);
};

var init = function() {
    addEventListeners();
};

var destroy = function() {
    removeEventListeners();
};

module.exports = {
    init,
    destroy
};
