var main = require('integrations/main');

main.baseFiles.clientSideValidation = require('./components/clientSideValidation');
main.baseFiles.cart = require('./cart');
main.baseFiles.menu = require('./components/menu');
main.baseFiles.header = require('./components/header');
main.baseFiles.miniCart = require('./components/miniCart');
main.baseFiles.slider = require('./components/slider').init;
main.baseFiles.footerBackToTop = require('./components/footerBackToTop');
main.baseFiles.floatingLabels = require('./components/floatingLabels').init;
main.baseFiles.quickView = require('./product/quickView');
main.baseFiles.labelOverSelect = require('./components/labelOverSelect').init;
main.baseFiles.textLineClamp = require('./components/textLineClamp').init;
main.baseFiles.base = require('./product/base');
main.baseFiles.giftCertificateButtons = require('./components/giftCertificateButtons').init;
main.baseFiles.giftCertificate = require('./giftCertificate/giftCertificate').init;
main.baseFiles.tabHelpers = require('./components/tabHelpers');
main.baseFiles.paymentControls = require('./components/paymentControls').init;
// main.baseFiles.productListEnhancementsLanding = require('./productListEnhancements/landing').init;
// main.baseFiles.productListEnhancementsDetail = require('./productListEnhancements/detail').init;
// main.baseFiles.productListEnhancementsModalShowLists = require('./productListEnhancements/modalShowLists').init;
// main.baseFiles.productListEnhancementsToggleProduct = require('./productListEnhancements/toggleProduct').init;
main.baseFiles.blogToggles = require('./components/blogToggles').init;
main.baseFiles.infoDropdownToggles = require('./components/infoDropdownToggles').init;
main.baseFiles.printPage = require('./components/printPage').init;
main.baseFiles.designBar = require('./designBar');
main.baseFiles.footer = require('./components/footer');
main.baseFiles.attentiveButton = require('./components/attentiveButton').init;
main.baseFiles.sfcppayment = require('./integrations/commercepayments/components/payment');
main.baseFiles.wishlist = require('./wishlist/wishlist').init;
main.baseFiles.emailSignUpFormStandard = require('./components/emailSignUpFormStandard');
// integration point for collect.js wishlist tracking
require('./salesforceServices/collect').wishlist();
require('./components/spinner');
require('./components/timeTicker');

module.exports = main;
