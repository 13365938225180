'use strict';
const baseDetail = require('core/productListEnhancements/detail');

const modalShareList = require('./modalShareList');
const modalShowQuote = require('./modalShowQuote');
const modalDeleteProducts = require('./modalDeleteProducts');
const overlayDetailSelected = require('./overlayDetailSelected');
const cardProduct = require('./cardProduct');
const modalQuickView = require('./modalQuickView');
const productBase = require('../product/base');
const detailNotes = require('./detailNotes');
const errorDisplay = require('./errorDisplay');

var unsetSelectedProductsState = function() {
    $('.product-list-enhancements-detail-nav').addClass('open');
    $('.product-list-enhancements-detail-deselect').removeClass('show');
    $('#productListEnhancementsOverlayDetailSelected').trigger('close');
    $('.product-list-enhancements-detail')
        .find('.product-list-enhancements-card-product')
        .trigger('unselect');
};

var toggleSelectedProductsState = function() {
    if ($('.product-list-enhancements-card-product[data-selected="true"]').length) {
        $('.product-list-enhancements-detail-nav').removeClass('open');
        $('.product-list-enhancements-detail-deselect').addClass('show');
        $('#productListEnhancementsOverlayDetailSelected').trigger('open');
    } else {
        unsetSelectedProductsState();
    }
};

var eventStopPropagation = function(e) {
    e.stopPropagation();
};

var eventKeyPressEscapeWindow = function (e) {
    if (e.key && e.key === 'Escape') {
        unsetSelectedProductsState();
    }
};

var eventUnselectAllProducts = function(e) {
    unsetSelectedProductsState();
};

var eventUpdateSelectedProducts = function() {
    toggleSelectedProductsState();
};

var eventQuoteSuccess = function() {
    $('#productListEnhancementsModalShowQuote').modal('hide');
    $('#productListEnhancementsModalShowQuoteSuccess').modal('show');
};

var eventQuoteFailure = function() {
    $('#productListEnhancementsModalShowQuote').modal('hide');
    $('#productListEnhancementsModalShowQuoteFailure').modal('show');
};

var eventUpdateProducts = function(e, $products) {
    $.spinner().start();
    unsetSelectedProductsState();
    cardProduct.destroy();
    $('.product-list-enhancements-detail')
        .find('.product-list-enhancements-detail-products')
        .replaceWith($products);
    cardProduct.init();
    $.spinner().stop();
};

var eventClickSelectAll = function(e) {
    e.preventDefault();
    $('.product-list-enhancements-detail')
        .find('.product-list-enhancements-card-product')
        .trigger('select');
    toggleSelectedProductsState();
};

var eventClickDeselect = function(e) {
    e.preventDefault();
    unsetSelectedProductsState();
};

var eventClickRequestQuote = function(e) {
    e.preventDefault();
    var $productListDetail = $(this).parents('.product-list-enhancements-detail');
    var $products = $productListDetail.find('.product-list-enhancements-card-product');
    var pids = [];
    $products.each(function() {
        var $product = $(this);
        pids.push($product.data().pid.toString());
    });
    $('#productListEnhancementsModalShowQuote')
        .data('pids', pids)
        .data('listId', $productListDetail.data().listId)
        .modal('show', $(this));
};

var eventClickShare = function (e) {
    e.preventDefault();
};

var eventClickAddToCart = function (e) {
    e.preventDefault();
    var $this = $(this);
    var $products = $this.parents('.product-list-enhancements-detail').find('.product-list-enhancements-card-product');
    var addToCartUrl = $this.data().url;
    var pidsObj = [];
    $products.each(function() {
        var $product = $(this);
        pidsObj.push({
            pid: $product.data().pid,
            qty: $product.find('.product-list-enhancements-card-product-quantity').val(),
            options: ''
        });
    });
    if (pidsObj.length) {
        $.spinner().start();
        pidsObj = JSON.stringify(pidsObj);
        $.ajax({
            url: addToCartUrl,
            method: 'POST',
            data: {
                pidsObj
            },
            success: function (data) {
                if (data.error === false) {
                    $('.product-list-enhancements-global-error-display').trigger('clear');
                    productBase.customHandlePostCartAdd(data);
                    $('body').trigger('product:afterAddToCart', data);
                    productBase.methods.miniCartReportingUrl(data.reportingURL, data.error);
                } else {
                    $('.product-list-enhancements-global-error-display').trigger('error');
                }
                $.spinner().stop();
            },
            error: function () {
                $('.product-list-enhancements-global-error-display').trigger('error');
                $.spinner().stop();
            }
        });
    }
};

var addEventListeners = function() {
    $(window)
        .on('keydown', eventKeyPressEscapeWindow);
    $('.product-list-enhancements-detail')
        .on('quoteSuccess', eventQuoteSuccess)
        .on('quoteFailure', eventQuoteFailure)
        .on('updateProducts', eventUpdateProducts)
        .on('afterSelectProduct', eventUpdateSelectedProducts)
        .on('unselectAllProducts', eventUnselectAllProducts)
        .on('click', '.product-list-enhancements-detail-select-all', eventClickSelectAll)
        .on('click', '.product-list-enhancements-detail-share', eventClickShare)
        .on('click', '.product-list-enhancements-detail-add-cart', eventClickAddToCart)
        .on('click', '.product-list-enhancements-detail-deselect', eventClickDeselect)
        .on('click', '.product-list-enhancements-detail-request-quote', eventClickRequestQuote);
    // this allows 2 bootstrap modals to be open at the same time
    $('#fragileLearnMoreModal, #learnMoreModal')
        .on('click keydown keypress keyup', eventStopPropagation);

};

var removeEventListeners = function() {
    $(window)
        .off('keydown', eventKeyPressEscapeWindow);
    $('.product-list-enhancements-detail')
        .off('quoteSuccess', eventQuoteSuccess)
        .off('quoteFailure', eventQuoteFailure)
        .off('updateProducts', eventUpdateProducts)
        .off('afterSelectProduct', eventUpdateSelectedProducts)
        .off('unselectAllProducts', eventUnselectAllProducts)
        .off('click', '.product-list-enhancements-detail-select-all', eventClickSelectAll)
        .off('click', '.product-list-enhancements-detail-share', eventClickShare)
        .off('click', '.product-list-enhancements-detail-add-cart', eventClickAddToCart)
        .off('click', '.product-list-enhancements-detail-deselect', eventClickDeselect)
        .off('click', '.product-list-enhancements-detail-request-quote', eventClickRequestQuote);
    // this allows 2 bootstrap modals to be open at the same time
    $('#fragileLearnMoreModal, #learnMoreModal')
        .off('click keydown keypress keyup', eventStopPropagation);
};

var init = function() {
    if ($('.product-list-enhancements-detail').length) {
        addEventListeners();
        modalShareList.init();
        modalShowQuote.init();
        modalDeleteProducts.init();
        overlayDetailSelected.init();
        cardProduct.init();
        modalQuickView.init();
        detailNotes.init();
        errorDisplay.init();
    }
};

var destroy = function() {
    removeEventListeners();
    modalShareList.destroy();
    modalShowQuote.destroy();
    modalDeleteProducts.destroy();
    overlayDetailSelected.destroy();
    cardProduct.destroy();
    modalQuickView.init();
    detailNotes.destroy();
    errorDisplay.destroy();
};

baseDetail.init = init;
baseDetail.destroy = destroy;

module.exports = baseDetail;
