'use strict';

var eventShow = function(e) {
    $(this)
        .attr({
            'data-list-id': e.relatedTarget.data().listId,
            'data-list-item-id': e.relatedTarget.data().listItemId
        })
        .data({
            listId: e.relatedTarget.data().listId,
            listItemId: e.relatedTarget.data().listItemId
        });
};

var eventClose = function(e) {
    $(this)
        .attr({
            'data-list-id': '',
            'data-list-item-id': ''
        })
        .data({
            listId: '',
            listItemId: ''
        });
};

var eventClickDelete = function(e) {
    e.preventDefault();
    var $modal = $('#wishlistEnhancementsModalDeleteProduct');
    $.spinner().start();
    $.ajax({
        url: $modal.data().url,
        method: 'POST',
        dataType: 'html',
        data: {
            listId: $modal.data().listId,
            listItemId: $modal.data().listItemId
        },
        success: function(html) {
            $('.product-list-enhancements-global-error-display').trigger('clear');
            $('.product-list-enhancements-detail').trigger('updateProducts', $(html).find('.product-list-enhancements-detail-products'));
            $modal.modal('hide');
        },
        error: function() {
            $modal.modal('hide');
            $('.product-list-enhancements-global-error-display').trigger('error');
            $.spinner().stop();
        }
    });
}

var addEventListeners = function() {
    $('#wishlistEnhancementsModalDeleteProduct')
        .on('click', '.product-list-enhancements-modal-delete-product-btn', eventClickDelete)
        .on('show.bs.modal', eventShow)
        .on('hidden.bs.modal', eventClose);
};

var removeEventListeners = function() {
    $('#wishlistEnhancementsModalDeleteProduct')
        .off('click', '.product-list-enhancements-modal-delete-product-btn', eventClickDelete)
        .off('show.bs.modal', eventShow)
        .off('hidden.bs.modal', eventClose);
};

var init = function() {
    addEventListeners();
}

var destroy = function() {
    removeEventListeners();
}

module.exports = {
    init,
    destroy
}
