'use strict';

var eventShow = function(e) {
    var $this = $(this);
    $this
        .find('#editProductListName')
        .val($this.data().productListName);
};

var eventClose = function(e) {
    $(this)
        .removeAttr('data-product-list-id')
        .removeAttr('data-product-list-name')
        .removeData('productListId')
        .removeData('productListName')
        .find('#editProductListName')
        .val('&nbsp;')
        .removeClass('is-invalid')
        .next('.invalid-feedback')
        .text('')
        .removeClass('d-block');
    $('.product-list-enhancements-edit-list-form .save')
        .removeAttr('disabled');
};

var eventKeyupName = function(e) {
    var $this = $(this);
    var $invalidFeedback = $this.next('.invalid-feedback');
    var $submitButton = $('.product-list-enhancements-edit-list-form .save');
    var nameOriginal = $('#wishlistEnhancementsModalEditList').data('productListName').replace(/[^A-Za-z0-9]/g, '').toLowerCase();
    var nameNew = $this.val().replace(/[^A-Za-z0-9]/g, '').toLowerCase();

    if (
        nameOriginal !== nameNew
        && $('#wishlistEnhancementsModalEditList').data().listNames.indexOf(nameNew) !== -1
    ) {
        $submitButton.attr('disabled', 'disabled');
        $invalidFeedback.text($this.data().errorDuplicateName).addClass('d-block');
        $this.addClass('is-invalid');
    } else {
        $submitButton.removeAttr('disabled');
        $invalidFeedback.text('').removeClass('d-block');
        $this.removeClass('is-invalid');
    }
};

var eventSubmitForm = function(e) {
    e.preventDefault();
    var $modal = $('#wishlistEnhancementsModalEditList');
    $.spinner().start();
    $.ajax({
        url: $modal.data().url,
        method: 'POST',
        dataType: 'html',
        data: {
            productListId: $modal.data().productListId,
            productListName: $('#editProductListName').val(),
            paginationPage: $('.product-list-enhancements-landing-pagination').data().page
        },
        success: function(html) {
            var $html = $(html);

            $('.product-list-enhancements-global-error-display').trigger('clear');
            $('.product-list-enhancements-landing').trigger('updateLists', {
                $lists: $html.find('.lists'),
                $listsPagination: $html.find('.product-list-enhancements-landing-pagination')
            });
            $modal.modal('hide');
        },
        error: function() {
            $modal.modal('hide');
            $('.product-list-enhancements-global-error-display').trigger('error');
            $.spinner().stop();
        }
    });
};

var eventClickDelete = function(e) {
    e.preventDefault();
    var $modal = $('#wishlistEnhancementsModalEditList');
    var productListId = $modal.data().productListId;
    $modal.modal('hide');
    $('.product-list-enhancements-landing').trigger('openDeleteList', productListId);
};

var addEventListeners = function() {
    $('#wishlistEnhancementsModalEditList')
        .on('show.bs.modal', eventShow)
        .on('hidden.bs.modal', eventClose)
        .on('keyup', '#editProductListName', eventKeyupName)
        .on('submit', '.product-list-enhancements-edit-list-form', eventSubmitForm)
        .on('click', '.delete', eventClickDelete);
};

var removeEventListeners = function() {
    $('#wishlistEnhancementsModalEditList')
        .off('show.bs.modal', eventShow)
        .off('hidden.bs.modal', eventClose)
        .off('keyup', '#editProductListName', eventKeyupName)
        .off('submit', '.product-list-enhancements-edit-list-form', eventSubmitForm)
        .off('click', '.delete', eventClickDelete);
};

var init = function() {
    addEventListeners();
}

var destroy = function() {
    removeEventListeners();
}

module.exports = {
    init,
    destroy
}
