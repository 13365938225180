function getFormattedDate(date) {
    var myDate = new Date(date);
    var dd = myDate.getDate() < 10 ? '0' + Number(myDate.getDate() + 1) : Number(myDate.getDate() + 1);
    var mm = (myDate.getMonth() + 1) < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1;
    var yy = myDate.getFullYear();

    return (yy + '-' + mm + '-' + dd);
}

/**
 * Ensures floating label fields in QV that have been populated with JS are triggered via focus
 * @param {*} modal
 */
function tiggerModalFormFocus(modal) {
    var formEls = ['_purchase_amount', '_purchase_senderEmail', '_purchase_sendDate', '_purchase_recipient', '_purchase_recipientEmail', '_purchase_message'];

    // trigger floating elements
    formEls.forEach(function(formEl) {
        var event,
            el = modal[0].querySelector('[name*=' + formEl + ']');

        if (el) {
            event = new Event('focus');
            el.dispatchEvent(event);
        }
    });
}

// returns formatted assembly product option
function getAssemblyOptions(el) {
    var selectedOptionValueIds = [
        {
            optionId: el.getAttribute('data-option-id'),
            selectedValueId: el.checked ? 'assembly' : 'noAssembly'
        }
    ];
    return JSON.stringify(selectedOptionValueIds);
}

module.exports = {
    getFormattedDate: getFormattedDate,
    tiggerModalFormFocus: tiggerModalFormFocus,
    getAssemblyOptions: getAssemblyOptions
}
