'use strict';

const base = require('core/components/footer');
const collectionServices = require('../salesforceServices/collect');

base.init();
base.backToTop();

// integration point for collect.js email collection
(() => {
    // add listener for identification/email collection
    collectionServices.emailCollection();

    // Filter for foot email form
    const $emailForm = $('form').find('.subscribe-email').closest('form');
    $emailForm.on('footer:signup:success', (e, data) => {
        $('body').trigger('email:emailCapture', {
            email: (data && data.b2ccrm) ? data.b2ccrm.contactId : ''
        });
    });

    //Remove full height padding from PLP
    if ( $('[data-action=Search-Show]').length && $('#footercontent.collapsible').length ) {
        $('body').css('padding-bottom', '120px');
    }

    //Toggle between footer content
    $("#footer_toggle").on('click', function() {
        $(this).toggleClass('active');
        $('#footercontent').toggleClass('expanded');
        $('.footer-container').slideToggle(500);
        $('.mini_footer').fadeToggle(400);
    });
})();

