'use strict';
/**
 * Track pushes data for respective tracking service to  window._etmc
 *
 * @param {*} trackService
 * @param {*} data
 */
const track = (trackService, data) => {
    if (window._etmc) {
        _etmc.push(["setOrgId", "514008248"]);
        _etmc.push([ trackService, data ]);
        return true;
    } else {
        console.warn('Error from salesforceServices/collect.js: "_etmc" is not available');
        return false;
    }
};

// this object sets the track services
const trackServices = {
    // Page View tracking
    pageView: (data) => track("trackPageView", data),
    // Cart tracking
    cart: (data) => track("trackCart", data),
    // Order Conversion/purchasing tracking
    conversion: (data) => track("trackConversion", data),
    // Wish list tracking
    wishlist: (data) => track("trackWishlist", data),
    // identification tracking - email address
    identification: (data) => {
        // user tracking also requires "trackPageView"
        if (track('setUserInfo', data)){
            _etmc.push(["trackPageView"]);
        }
    }
};

/**
 * getCartItems maps through the items Array in the cart and returns a new array of specifc item data
 *
 * @param {*} data
 * @return { Array } - newly mapped Items array
 */
const getCartItems = (cart) => {
    if (cart && cart.items) {
        return cart.items.map(item => {
            return {
                'item': item.productName,
                "quantity": item.quantity,
                "price": item.priceTotal.price,
                "unique_id": item.id
            };
        });
    }
    return [];
};

module.exports = {
    /**
     * Uses UI components from category/serach pages to construct the apprproate data for tracking
     *
     */
    categorySearchPage : () => {
        // wait until page loads to grab data from ui components
        $(() => {
            // categorySearch is either cgid or search term, depending on page type
            const categorySearch = $('[data-collect-category]').data('collectCategorySearch');

            // determine if page is category or search result, track accordingly
            let pageType = 'search';
            if ($('.page').data('querystring').toLowerCase().includes('cgid')) pageType = 'category';

            // collect data
            trackServices.pageView({ pageType: categorySearch });
        });
    },
    /**
     * Uses UI components from the PDP to construct the apprproate data for tracking
     *
     */
    productDetailPage : () => {
        // wait until page loads to grab data from ui components
        $(() => trackServices.pageView({ 'item': $('.product-detail').data('masterId') }));
    },
    /**
     * Collects data whenever products are added to the cart
     * Uses ajax resposne from the cart events 'product:afterAddToCart' and 'cart:update' to construct the apprproate data for tracking
     *
     */
    cartUpdates: () => {
        $('body').on('product:afterAddToCart cart:update', (e, data) => {
            // event data is slightly different based on eventType
            data = e.type === 'cart:update' ? data : data.cart;

            // if cart is empty track for clear_cart
            let cartData;
            const items = getCartItems(data);

            items.length ? cartData = { 'cart': items } : cartData = { 'clear_cart': true };

            trackServices.cart(cartData);
        });
    },
    /**
     * Uses ajax resposne from the event 'checkout:afterPlaceOrder' to construct the apprproate data for tracking
     *
     */
    purchase: () => {
        $('body').on('checkout:afterPlaceOrder', (e, data) => trackServices.conversion({ 'cart' : getCartItems(data) }));
    },
    /**
     * Uses window.UncachedData properties pass through jquery event 'wishlist:wishlistUpdate' for tracking "add to wishlist"
     */
    wishlist: () => {
        $('body').on('wishlist:wishlistUpdate', (e, data) => trackServices.wishlist({ 'items': data.items, 'skus': data.skus }));
    },
    /**
     * Uses JQUERY custom event 'email:emailCapture' to construct the apprproate data for tracking
     */
    emailCollection: () => {
        $('body').on('email:emailCapture', (e, data) => {
            if (data && data.email) trackServices.identification({ "email" : data.email }
        )
    });
    },
}