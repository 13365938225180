'use strict';

var toastHelpers = require('core/components/toast');
var collectionServices = require('../salesforceServices/collect');

var init = function init() {
    collectionServices.emailCollection();

    $('form.email-sign-up-form-standard').on('submit', function(e) {
		e.preventDefault();

		var $form = $(this);
		var $inputs = $form.find(':input:not(:disabled)');
		var $btn = $form.find('button');
        var xhr = $form.data('xhr');
        var emailAddress = $form.find('[name=emailId]').val().trim();

        $form.data('xhr', $.ajax({
            url: $form.find('[data-href]').attr('data-href'),
            type: 'post',
            data: $inputs.serialize(),
            dataType: 'json',
            beforeSend: function () {
                // Drop duplicate requests
                xhr && xhr.abort && xhr.abort();

                $.spinner().start();

                $btn.attr('disabled', true);
            },
            success: function (data) {
                if (data.error) {
                    toastHelpers.methods.show('danger', data.msg, false);
                } else {
                    toastHelpers.methods.show('success', data.msg, false);
                    $('body').trigger('email:emailCapture', {
                        email: emailAddress
                    });
                }
            },
            error: function ($xhr, err, other) {
                toastHelpers.methods.show('danger', err, false);
            },
            complete: function (data) {
                $.spinner().stop();
                $btn.removeAttr('disabled');
                $form[0].reset();
            }
        }));
	});
}

module.exports = {
    init: init()
};
