'use strict';

const productListEnhancementsHelpers = require('./helpers.js');

var eventClickEdit = function(e) {
    e.preventDefault();
    $('.product-list-enhancements-landing').trigger('openEditList', $(this).data());
};

var addEventListeners = function() {
    $('.product-list-enhancements-card-list')
        .on('click', '.product-list-enhancements-card-list-header-edit', eventClickEdit);
};

var removeEventListeners = function() {
    $('.product-list-enhancements-card-list')
        .off('click', '.product-list-enhancements-card-list-header-edit', eventClickEdit);
};

var init = function() {
    addEventListeners();
};

var destroy = function() {
    removeEventListeners();
};

module.exports = {
    init,
    destroy
};
