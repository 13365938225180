'use strict';

/* Copied from SG to support floating label input events */

// const util = require('./util');
function floatLabel(field, event) {
    let label = $('label[for="' + field.attr('id') + '"]');

    if (event.type == 'blur') {
        if (field.val() == '') {
            label.removeClass('form-floating-label-transform');
        }
    } else {
        label.addClass('form-floating-label-transform');
    }
}

module.exports = {
    init: (target) => {
        let notCheckboxElements = target ? target : document.querySelectorAll('.form-group:not(.custom-control, .custom-checkbox, .form-floating-none)');

        Array.from(notCheckboxElements).forEach(formGroupElements => {
            formGroupElements.classList.add('form-floating');

            let customSelectElement = formGroupElements.querySelectorAll('.custom-select');
            if (customSelectElement.length !== 0 && customSelectElement[0].previousElementSibling) {
                customSelectElement[0].previousElementSibling.classList.add('form-floating-label-transform');
            }

            $(formGroupElements).find('input, textarea, select').each(function() {
                let inputField = $(this);
                
                if (inputField.val() !== null && inputField.val().length > 0 || this.type === 'date') {
                    floatLabel(inputField, 'load');
                } else {
                    try {
                        if (inputField.is(':-webkit-autofill')) floatLabel(inputField, 'load'); // chrome
                    } catch (error){
                        return false;
                    }
                }
            }).on('blur input focus', function(event) {
                let inputField = $(this);
                floatLabel(inputField, event);
            });
        });
    }
};