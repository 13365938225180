'use strict';

var attentiveButton = document.querySelector('[data-attentive-trigger]');
var email = $('[data-attentive-email]').attr('data-attentive-email');

var triggerAttentive = function triggerAttentive() {
    if (window.__attentive) {
        if (window.innerWidth >= 760) {
            //call the desktop creative
            window.__attentive.trigger(null, null, email || null, 126601);
        } else {
            //call the mobile creative
            window.__attentive.trigger(null, null, email || null, 126595);
        }
    }
}

var init = function init() {
    if (attentiveButton) {
        attentiveButton.addEventListener('click', triggerAttentive);
    }
}

module.exports = {
    init: init()
};
