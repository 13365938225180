'use strict';

var productListEnhancementsHelpers = require('./helpers.js');

var swatchChange =function($swatch) {
    var $tileParent = $swatch.parents('.product-tile');
    //main Product List Enhancements Link
    if ($swatch.attr('data-productlistenhancementspid')) {
        toggleButtonSelectedState($tileParent, $swatch.attr('data-productlistenhancementspid'));
    }
};

var hijackSwatchClick = function() {
    if (window.isMobile()) {
        $('body').on('click.swatch-link', '.product-tile .swatch-link', eventHijackSwatchClick);
    } else {
        $('body').off('click.swatch-link');
    }
};

var toggleButtonSelectedState = function($container, pid) {
    //Make heart icon accurate
    var mainProductListEnhancements = $container.find('[data-productlistenhancementspid]');
    if (mainProductListEnhancements.length) {
        mainProductListEnhancements.attr('data-productlistenhancementspid', pid);
        //Make heart icon accurate
        productListEnhancementsHelpers.updateLinkData(mainProductListEnhancements);
    }
};

var toggleProductGuest = function(data, pid) {
    $.ajax({
        url: data.url,
        method: 'POST',
        dataType: 'json',
        data: {
            pid: pid,
            quantity: data.productlistenhancementsquantity
        },
        success: function(data) {
            if (data.success) {
                $('.product-list-enhancements-global-error-display').trigger('clear');
                productListEnhancementsHelpers.updateUncachedData({
                    actionType: data.productListActionType,
                    pid: data.pid
                });
                productListEnhancementsHelpers.updateLinkData(false, data);
                productListEnhancementsHelpers.openToast(data);

                // trigger point for collect.js tracking
                $('body').trigger('wishlist:wishlistUpdate', {
                    items: window.UncachedData.PLEMasterIds,
                    skus: window.UncachedData.productListEnhancementsPIDs,
                });

            } else {
                $('.product-list-enhancements-global-error-display').trigger('error');
            }
        },
        error: function() {
            $('.product-list-enhancements-global-error-display').trigger('error');
        }
    });
};

var eventClickHeart = function(e) {
    e.preventDefault();
    var $this = $(this);
    if (window.UncachedData.customer.isAuthenticated) {
        if ($this.find('.selected').length && $this.parents('.product-detail').length) {
            $('#productListEnhancementsOverlayProductDetail')
                .data('pid', $(this).attr('data-productlistenhancementspid') )
                .trigger('open');
        } else {
            $('#productListEnhancementsModalShowLists')
                .data('pid', $(this).attr('data-productlistenhancementspid') )
                .data('multiSelect', false)
                .modal('show', $(this));
        }
    } else {
        toggleProductGuest($(this).data(), $(this).attr('data-productlistenhancementspid'));
    }
};

var eventMouseEnterSwatch = function() {
    swatchChange($(this));
};

var eventAfterAttributeSelect = function(e, payload) {
    var $iconParent = $('.primary-images');
    toggleButtonSelectedState($iconParent, payload.data.product.productlistenhancementspid);
};

var eventHijackSwatchClick = function(e) {
    if( !(($(this).hasClass('solid_color_swatch') && $('#disableSwatchHover').length) || $(this).hasClass('disable_hover')) ) {
        e.preventDefault();
    }

    swatchChange($(this).find('.swatch'));
};

var init = function() {
    $('body')
        .on('click', 'button[data-productlistenhancementspid]', eventClickHeart)
        .on('mouseenter', '.product-tile .swatch', eventMouseEnterSwatch)
        .on('product:afterAttributeSelect', eventAfterAttributeSelect);
    $(window).on('resize',function() {
        hijackSwatchClick();
    });
    hijackSwatchClick();
    productListEnhancementsHelpers.updateLinkData();
}

var destroy = function() {
    $('body')
        .off('click', '[data-productlistenhancementspid]', eventClickHeart)
        .off('mouseenter', '.product-tile .swatch', eventMouseEnterSwatch)
        .off('product:afterAttributeSelect', eventAfterAttributeSelect);
};

module.exports = {
    init,
    destroy
};
