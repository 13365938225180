const coreWishlist = require('core/wishlist/wishlist');

coreWishlist.init = function () {
    coreWishlist.cartUpdate();
    coreWishlist.toggleWishlist();
    coreWishlist.updateWishlistLinkData();
    coreWishlist.copyWishlistLink();
    coreWishlist.updateQuickView();
    coreWishlist.viewProductViaEdit();
    coreWishlist.focusEditWishlistProductModal();
    coreWishlist.onClosingEditWishlistProductModal();
    coreWishlist.trapEditWishlistProductModalFocus();
    coreWishlist.addToCartFromWishlist();
    coreWishlist.toggleWishlistStatus();
    coreWishlist.toggleWishlistItemStatus();
    coreWishlist.removeFromWishlist();
    coreWishlist.moreWLItems();
    require('../productListEnhancements/landing').init();
    require('../productListEnhancements/detail').init();
    require('../productListEnhancements/modalShowLists').init();
    require('../productListEnhancements/toggleProduct').init();
    require('../productListEnhancements/toast').init();
}

module.exports = coreWishlist;