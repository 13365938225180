'use strict';

var recaptcha = require('core/components/recaptcha');

/**
 * Register payment request buttons on the page that have not yet been registered.
 */
function registerPaymentRequestButtons() {
    if (window.sfpp) {
        sfpp.ready(function () {   // eslint-disable-line
            $('.salesforce-paymentrequest-element').each(function () {
                var element = $(this);
                if (element.hasClass('salesforce-paymentrequest-element-registered')) {
                    // Only register each button once
                    return;
                }

                element.addClass('salesforce-paymentrequest-element-registered');

                var errorElement = $('.' + element.data('errorsclass'));
                var paymentrequest = sfpp.get(element.data('paymentrequestid'));   // eslint-disable-line

                // Update current payment request with initial data
                var abPaymentrequestoptions = element.data('abPaymentrequestoptions');
                if (abPaymentrequestoptions) {
                    paymentrequest.updatePaymentRequest(abPaymentrequestoptions);
                }

                // Update error message on change
                paymentrequest.on('change', function (event) {
                    if (event.error) {
                        // Inform the customer that there is an error.
                        errorElement.empty().text(event.error.message);
                    } else {
                        // Clear out error message
                        errorElement.empty();
                    }
                });

                $('body').on('cart:beforeUpdate cart:beforeShippingMethodSelected checkout:beforeShippingMethodSelected promotion:beforeUpdate checkout:beforeGiftCertificateUpdate', function () {
                    element.attr('disabled', true);
                });

                $('body').on('cart:update cart:shippingMethodSelected checkout:shippingMethodSelected promotion:success promotion:error checkout:giftCertificateUpdate', function (e, data) {
                    if (data.paymentRequestOptions) {
                        paymentrequest.updatePaymentRequest(data.paymentRequestOptions);
                        var enabled = data.paymentRequestOptions.total.amount > 0;
                        element.attr('disabled', !enabled);
                    }
                });

                paymentrequest.on('payment', function (e) {
                    recaptcha.check(e, {
                        url: element.data('placeorder'),
                        method: 'POST',
                        success: function (data) {
                            if (data.error) {
                                // Inform the customer that there is an error.
                                errorElement.empty().text(data.errorMessage);
                            } else {
                                // Show the order confirmation page

                                var redirect = $('<form>')
                                    .appendTo(document.body)
                                    .attr({
                                        method: 'POST',
                                        action: data.continueUrl
                                    });

                                $('<input>')
                                    .appendTo(redirect)
                                    .attr({
                                        name: 'orderID',
                                        value: data.orderID
                                    });

                                $('<input>')
                                    .appendTo(redirect)
                                    .attr({
                                        name: 'orderToken',
                                        value: data.orderToken
                                    });

                                redirect.submit();
                            }
                        },
                        error: function (err) {
                            // Inform the customer that there is an error.
                            errorElement.empty().text(err.message);
                        }
                    });
                });
            });
        });
    }
}

function onPaymentRequestButtonRegister() {
    $('body').on('paymentrequestbutton:register', function () {
        module.exports.methods.registerPaymentRequestButtons();
    });
}

function onTrackBuyNowiframe () {
    $('body').on('PaymentMethodObserver:AddNode', function (e, data) {
        if (data && data.addNode && data.addNode.classList){
            for (var className of data.addNode.classList) {
                if (className === 'StripeElement') {
                    var container = $(data.target).find('.js-paymentmethodwarning-msgcontainer');
                    if (container.length) {
                        container.attr('data-iframepresent', true);
                        container.data('iframepresent', true);
                        $('body').trigger('PaymentMethodObserver:iframePresent');
                        data.observer.disconnect();
                    }
                    return;
                }
            }
        }
    })
}

function onTrackCreditCardiframe () {
    $('body').on('PaymentMethodObserver:AddNode', function (e, data) {
        if (data && data.addNode && data.addNode.classList){
            for (var className of data.addNode.classList) {
                if (className === 'sfpp-payment-method-card') {
                    var container = $(data.target).find('.js-paymentmethodwarning-msgcontainer');
                    if (container.length) {
                        container.attr('data-iframepresent', true);
                        container.data('iframepresent', true);
                        $('body').trigger('PaymentMethodObserver:iframePresent');
                        data.observer.disconnect();
                    }
                    return;
                }
            }
        }
    });
}

module.exports = {
    methods: {
        registerPaymentRequestButtons: registerPaymentRequestButtons,
    },
    onTrackBuyNowiframe: onTrackBuyNowiframe,
    onTrackCreditCardiframe: onTrackCreditCardiframe,
    onPaymentRequestButtonRegister: onPaymentRequestButtonRegister
};
