'use strict';

/**
 * Info Dropdown Toggles
 * Updates Mobile Interface of secondary navigation to dropdowns
 */
 var SiteConstants = require('constants/SiteConstants'),
    mainDropdownNav = document.querySelector('[data-info-dropdown]'),
    allTriggers = mainDropdownNav ? mainDropdownNav.querySelectorAll('a, .info-dropdown-active-label') : null,
    activeLabel = mainDropdownNav ? mainDropdownNav.querySelector('[data-info-dropdown-active-label]') : null;

var triggerDropdownTrigger = function triggerDropdownTrigger(e) {
    var triggerParentElement = this.parentElement;
    e.preventDefault();

    // set active label text to current link
    activeLabel.textContent = this.textContent;

    if(this.hasAttribute('data-info-dropdown-trigger')) {
        triggerParentElement.classList.toggle('active');
    } else {
        let event = new Event('click');
        activeLabel.dispatchEvent(event);

        window.location.href = this.href;
    }
}

// move active item to top of parent
var moveActiveItemToFront = function moveActiveItemToFront() {
    if (window.innerWidth < SiteConstants.BreakpointSizes.md) {
        var activeEl = mainDropdownNav.querySelector('.info-nav-item.active'),
            activeMenu = activeEl ? $(activeEl).closest('.info-nav-item-dropdown.active') : null;
        if (activeMenu) {
            activeMenu.get(0).parentElement.prepend(activeMenu.get(0));
        }
    }
}

var addEventListenersDropdownTriggers = function() {
    [].forEach.call(allTriggers, function(trigger) {
        trigger.addEventListener('click', triggerDropdownTrigger)
    });
}

var removeEventListenersDropdownTriggers = function() {
    [].forEach.call(allTriggers, function(trigger) {
        trigger.removeEventListener('click', triggerDropdownTrigger)
    });
}

var setStaticLinkActive = function() {
    if( $('#active_page_id').length ) {
        let servicePageId = $('#active_page_id').val()
        $('.info-dropdown-trigger.d-md-none').html( $('[data-activepage=' + servicePageId + ']').find('a').text() );
        $('[data-activepage=' + servicePageId + ']').find('a').addClass('font-weight-bold');
    }
}

var setDropdownLinkActive = function() {
    if( $('.info-nav-item.active').length ) {
        $('.info-nav-item.active').parent('.info-dropdown-menu').siblings('.info-dropdown-trigger').addClass('font-weight-bold');
        $('.info-nav-item.active').parent('.info-dropdown-menu').parent('.info-nav-item-primary').addClass('active')
    }
}

var init = function() {
    if (mainDropdownNav) {
        addEventListenersDropdownTriggers();
        moveActiveItemToFront();
        setStaticLinkActive();
        setDropdownLinkActive();
    }
};

var destroy = function() {
    if (mainDropdownNav) {
        removeEventListenersDropdownTriggers();
    }
}

module.exports = {
    init: init,
    destroy: destroy
}
