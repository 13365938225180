'use strict';

var clearError = function() {
    $('.product-list-enhancements-global-error-display')
        .removeAttr('tabindex')
        .addClass('d-none');
};

var eventError = function() {

    //close modals so you can see the error message
    $(`
        #wishlistEnhancementsModalDeleteList,
        #wishlistEnhancementsModalDeleteProduct,
        #wishlistEnhancementsModalDeleteProducts,
        #wishlistEnhancementsModalCreateList,
        #wishlistEnhancementsModalEditList,
        #wishlistEnhancementsModalDeleteList,
        #wishlistEnhancementsModalShareList
    `).modal('hide');

    // close the spinner if it is open still
    $.spinner().stop();

    //display the error
    $('.product-list-enhancements-global-error-display')
        .removeClass('d-none')
        .attr('tabindex', '-1')
        .focus();
};

var eventClear = function() {
    clearError();
};

var eventClickClose = function(e) {
    e.preventDefault();
    clearError();
};

var eventKeyPressEscapeWindow = function(e) {
    if (e.key && e.key === 'Escape') {
        clearError();
    }
};

var addEventListeners = function() {
    $(window)
        .on('keydown', eventKeyPressEscapeWindow);
    $('.product-list-enhancements-global-error-display')
        .on('error', eventError)
        .on('clear', eventClear)
        .on('click', '.product-list-enhancements-global-error-display-close', eventClickClose);
};

var removeEventListeners = function() {
    $(window)
        .off('keydown', eventKeyPressEscapeWindow);
    $('.product-list-enhancements-global-error-display')
        .off('error', eventError)
        .off('clear', eventClear)
        .off('click', '.product-list-enhancements-global-error-display-close', eventClickClose);
};

var init = function() {
    addEventListeners();
};

var destroy = function() {
    removeEventListeners();
};

module.exports = {
    init,
    destroy
};
