'use strict';
const integrations = require('integrations/integrations/commercepayments/components/payment');

const intRegisterPaymentRequestButtons = integrations.methods.registerPaymentRequestButtons;
integrations.methods.registerPaymentRequestButtons = () => {

    // set integrations_core listeners for payment request buttons
    intRegisterPaymentRequestButtons();

    if (window.sfpp) {
        sfpp.ready( () => {
            $('.salesforce-paymentrequest-element').each( (i, el) => {
                const $element = $(el);
                const paymentrequest = sfpp.get($element.data('paymentrequestid'));

                // updates current payment request with initial data
                paymentrequest.updatePaymentRequest($element.data('abPaymentrequestoptions'));
            });
        });
    }
}

module.exports = integrations;
