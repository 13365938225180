'use strict';

/**
 * Toggle active class on gift certificate button
 * @param {*} e - DOM event
 */
function toggleButton(e) {
    var $parentEl = $(e.target).closest('.row'),
        $activeBtn = $parentEl.find('.active'),
        amountField = document.querySelector('#amount');

    e.preventDefault();

    if ($activeBtn) {
        $activeBtn.removeClass('active');
    }

    $(e.target).addClass('active');

    if (amountField) {
        $(amountField).removeClass('is-invalid').focus();
        $(amountField).next('.invalid-feedback').text("");
        amountField.value = e.target.value;
    }
}

// Init
function giftCertificateButtonsInit() {
    var giftButtons = document.querySelectorAll('[data-gift-cert-btn]'),
        amountField = document.querySelector('#amount');

    if (giftButtons.length > 0 && amountField) {
        [].forEach.call(giftButtons, function(btn) {
            btn.addEventListener('click', toggleButton);
        });

        // if input is clicked, then remove
        amountField.addEventListener('click', function(e) {
            var activeBtn = document.querySelector('[data-gift-cert-btn].active'),
                originalValue = e.target.value;

            // on blur remove active class on gift cert buttons if value in input has changed
            $(amountField).one( 'blur', function(e) {
                if ( activeBtn && (originalValue !== e.target.value) ) {
                    activeBtn.classList.remove('active');
                }
            });
        });
    }
}

module.exports = {
    init: giftCertificateButtonsInit
}
