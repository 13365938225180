function handleHeader() {
    $(window).on("scroll", function() {
        if($(window).scrollTop() > 400) {
            $(".sticky_header").addClass("invert");
        } else {
           $(".sticky_header").removeClass("invert");
        }
    });

    $(".drawer_controls").on("click", function() {
        $(".sticky_header").toggleClass("open");
    });

    $(".section_nav").on("click", function(event) {
        event.preventDefault();
        let target = $(this).attr('href');
        let offset = parseInt($(this).data('offset'));
        let duration = 2000;

        smoothScroll(target, offset, duration);
    });

    $(".custom_collapse_toggle").on("click", function(event) {
        event.preventDefault();
        let targetClass = $(this).data('target');

        $(targetClass).slideToggle(1000);

        if($(this).hasClass("portfolio_close")){
            let target = $(this).data('id');
            smoothScroll(target, 200, 1000);
            $(target).toggleClass("collapsed");
        } else {
            $(this).toggleClass("collapsed");
        }
    });
}

function handleHeroAnimation(currentFrame) {
    let nextFrame = $(currentFrame).data("frame");
    let timeout = $(currentFrame).data("time");
    let toggle = $(currentFrame).data("toggle");

    if( $(window).width() < 1000 && $(currentFrame).data("mframe") !== undefined ) {
        nextFrame = $(currentFrame).data("mframe");

        if($(currentFrame).data("mtime") !== undefined)
            timeout = $(currentFrame).data("mtime");
    }

    setTimeout(function(){
        $(nextFrame).css("opacity", "1");

        if(nextFrame === "#frame_1") {
            $(".animated_hero .fullview_frame:not(:first-child)").css("opacity", "0");
            $(".animated_hero h1, .animated_hero a").removeClass('set_visible');
            handleHeroAnimation("#frame_1");
        } else {

            switch (toggle) {
              case 'both':
                  $(".animated_hero h1, .animated_hero a").toggleClass('set_visible');
                break;
              case 'button':
                  $(".animated_hero a").toggleClass('set_visible');
                  break;
              case 'title':
                  $(".animated_hero h1").toggleClass('set_visible');
                break;
            }

            handleHeroAnimation(nextFrame);
        }

    }, timeout);
}

function smoothScroll(target, offset, duration) {
    $('html, body').animate({
        scrollTop: ($(target).offset().top - offset)
    }, duration);
}

function handleFormValidation() {
    $("input").on( "blur", function() {
        validateCurrentField( this );
    });

    $("select.form-control").on( "change", function() {
        if (this.value !== undefined) {
            $(this).next(".field_error").html("");
            $(this).parent().removeClass("failed");
        }
     });

    $("button#submitButton").on( "click", function(event) {
        $( "#contact_form input:required" ).each(function() {
            validateCurrentField( this );
        });

        if( !$( '.custom_group input[name=00N6g00000VKLuE]:checked' ).length ) {
            $('.custom_group').addClass('failed').find(".field_error").html("Please select atleast one option to continue.");
        } else {
            $('.custom_group').removeClass('failed').find(".field_error").html('');
        }

        if($('#00N6u000000jjIq').find(':selected').prop('disabled')){
            $("#00N6u000000jjIq").parent().find(".field_error").html("This field is required.");
            $("#00N6u000000jjIq").parent().addClass("failed");
        }

        if($('#00N6u000000jn7B').find(':selected').prop('disabled')){
            $("#00N6u000000jn7B").parent().find(".field_error").html("This field is required.");
            $("#00N6u000000jn7B").parent().addClass("failed");
        }

        if( $(".failed").length ) {
            smoothScroll('#contact_form', 330, 600);
            event.preventDefault();
        }

    });
}

function validateCurrentField(currentField) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if ( $(currentField).is(':required') && !$(currentField).val() ) {
        $(currentField).next(".field_error").html("This field is required.");
        $(currentField).parent().addClass("failed");
    } else if( $(currentField).is('[type=email]') && !$(currentField).val().match(re) && $(currentField).val() ) {
        $(currentField).next(".field_error").html("Please enter a valid email address.");
        $(currentField).parent().addClass("failed");
    } else {
        $(currentField).next(".field_error").html("");
        $(currentField).parent().removeClass("failed");
    }
}

function handleDatePicker() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;
    $('input[type=date]').attr('min',today);
}

function handleRedirect() {
    var urlSearchParams = new URLSearchParams(window.location.search);
    var target = '#' + urlSearchParams.get('target');
    let offset = parseInt( urlSearchParams.get('offset') );
    let duration = 2000;

    if(target && offset) {
        setTimeout(() => {smoothScroll(target, offset, duration)}, 1000);
    }
}

function init() {
    if( $('[data-querystring^="cid=interior-design-services"]').length ) {
        handleHeader();
        handleHeroAnimation("#frame_1");
        handleFormValidation();
        handleDatePicker();
        handleRedirect();
    }
}

module.exports = () => {
    init();
}