'use strict';

/**
 * Tab Helpers
 * Adds functionality to turn tabs into dropdown on small screensizes
 */

const debounce = require('lodash/debounce');
var SiteConstants = require('constants/SiteConstants');
var tabToggles = document.querySelectorAll('[data-nav-accordion-sm-tabs-md]');

/**
 * Ensures that when page is resized from mobile to desktop that the origianl index of elements is maintained
 * @param {*} tabToggles 
 */
function updateIndexes(tabToggles) {
    if (window.innerWidth > SiteConstants.BreakpointSizes.lg) {
        // screensize to desktop
        [].forEach.call(tabToggles, function(tabToggle) {
            if (tabToggle.hasAttribute('data-reindex-needed')) {

                for (let i = 0; i < tabToggle.children.length; i++) {
                    var selector = '[data-nav-accordion-sm-tabs-md-index="' + i + '"]',
                        tabToPrepend = tabToggle.querySelector(selector);

                    if (tabToPrepend) {
                        tabToggle.append(tabToPrepend.parentElement);
                    }
                }
                
                tabToggle.removeAttribute('data-reindex-needed');
            }       
        });
    } else {
        // screensize to mobile
        [].forEach.call(tabToggles, function(tabToggle) {
            // get active selected
            var selectedToggle = tabToggle.querySelector('.active'),
                selectedToggleIndex = $(selectedToggle.parentElement).index();
            // if selected index is not first, move it to first (for mobile sizes);
            if (selectedToggleIndex !== 0) {
                setToggleToFront(selectedToggle, tabToggle);
            }
        });
    }
}

function toggleTab(tab) {
    tab.classList.toggle('expand');
}

function setToggleToFront(tabNavLink, tabToggle) {
    var $tabItem = $(tabNavLink).closest('.nav-item');
    if ($tabItem) {
        $tabItem.parent().prepend($tabItem);
        tabToggle.setAttribute('data-reindex-needed', '');
    }
}

function init() {
    if (tabToggles.length > 0) {
        [].forEach.call(tabToggles, function(tabToggle) {
            var tabNavLinks = tabToggle.querySelectorAll('.nav-item > .nav-link');

            // add click for toggling expand on tab container
            tabToggle.addEventListener('click', function() {
                if (window.innerWidth < SiteConstants.BreakpointSizes.lg) {
                    toggleTab(this);
                }
            });

            // on small screens move active tab item to beginning of list
            if (tabNavLinks.length > 0) {
                [].forEach.call(tabNavLinks, function(tabNavLink) {
                    // set nav Index 
                    var tabIndex = [].indexOf.call(tabNavLinks, tabNavLink);
                    tabNavLink.setAttribute('data-nav-accordion-sm-tabs-md-index', tabIndex);

                    // add click
                    tabNavLink.addEventListener('click', function() {
                        if (window.innerWidth < SiteConstants.BreakpointSizes.lg) {
                            setToggleToFront(tabNavLink, tabToggle) 
                        }
                    });
                });
            }    
        });
    }

    // UPDATE INDEX ON RESIZE
     $(window).on('resize', debounce(function() {
        updateIndexes(tabToggles);
    }, 100));
}

module.exports = () => {
    init();
}
