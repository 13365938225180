'use strict';

const debounce = require('lodash/debounce');
const shave = require('shave').default;

const readMoreText = ' ... | Read More',
    readLessText = ' Read Less',
    options = {
        character: readMoreText
    };

// Paragarph styles get overwritten by shave.js
// save the font, line-height, and margin-bottom styles
// those will be used to add to the parent element.
function getParagraphStyles(el) {
    var paragraph = el.querySelector('p'),
        paragraphStyles = [],
        onlyStyles = ['font-size', 'font-family', 'font-weight', 'line-height', 'margin-bottom', 'margin-top'];

    if (paragraph) {
        var styles = window.getComputedStyle(paragraph);

        onlyStyles.forEach(function(style) {
            var obj = {};
            obj[style] = styles.getPropertyValue(""+style);
            paragraphStyles.push(obj);
        });
    }

    return paragraphStyles
}

function setElementStyles(el, styles) {
    for (var i=0; i < styles.length; i++) {
        el.style[Object.keys(styles[i])[0]] = Object.values(styles[i])[0]
    }
}

function getMaxHeight(el) {
    var lines = parseFloat(el.getAttribute('data-truncate-text'), 10),
        elLineHeight = parseFloat(window.getComputedStyle(el, null).getPropertyValue("line-height"), 10),
        maxHeight = parseInt(lines * elLineHeight, 10);

    return maxHeight;
}

function toggleReadMore(target) {
    var container = $(target).closest('[data-truncate-text]')[0];

    if (target.classList.contains('js-shave-char')) {
        if (container.classList.contains('clamped')) {
            container.classList.remove('clamped');
            container.querySelector('.js-shave').style.display = 'inline';
            target.parentElement.appendChild(target);
            target.innerText = readLessText;
        }   else {
            shave(container, getMaxHeight(container), options);
            container.classList.add('clamped');
        }
    }
}

function setupClampText(el) {
    var maxHeight = getMaxHeight(el),
        paragraphStyles = getParagraphStyles(el),
        debounceResizeFn = debounce(function() {
            if (el.classList.contains('clamped')) {
                shave(el, maxHeight, options);
            }
        }, 100);

    // run once
    setElementStyles(el, paragraphStyles);
    maxHeight = getMaxHeight(el);

    // init functions
    shave(el, maxHeight, options);
    el.classList.add('clamped');

    // add to resize
    window.addEventListener('resize', debounceResizeFn);

    // add read more/less toggle
    el.addEventListener('click', function(e) {
        toggleReadMore(e.target);
    });
}

function init() {
    var texts = document.querySelectorAll('[data-truncate-text]'),
        truncatedTexts = [].filter.call(texts, text => text.getAttribute('data-truncate-text') !== '');

    if (truncatedTexts.length > 0) {
        // setup truncatedText Functionality for each one
        truncatedTexts.forEach(function(truncatedText) {
            setupClampText(truncatedText);
        });
    }
}

module.exports = {
    init: function() {
        window.addEventListener('load', (event) => {
            init();
        });
    }
}
