'use strict';

const productListEnhancementsHelpers = require('./helpers.js');
const productAssemblyOptionId = 'productAssembly';

var setAddressAddState = function($quoteOption) {
    var $stage1 = $quoteOption.find('.address-stage-1');
    var $stage2 = $stage1.find('.address-stage-2');
    $quoteOption.find('.product-list-enhancements-modal-quote-address-change').val('new');
    $stage1.find('.product-list-enhancements-modal-quote-address').val('new');
    clearAddressForm($stage1);
    $stage2.addClass('open');
    $stage1.find('.product-list-enhancements-modal-quote-address-modify').addClass('d-none');
    toggleAddressFormFieldRequiredState($stage1, true);
};

var toggleAddressFormFieldRequiredState = function($context, isRequiredEnabledState) {
    if (isRequiredEnabledState) {
        $context
            .find('input[required-disabled], select[required-disabled]')
            .removeAttr('required-disabled')
            .removeAttr('aria-required-disabled')
            .attr('required', 'required')
            .attr('aria-required', 'true');
        $context
            .find('.required-disabled')
            .removeClass('required-disabled')
            .addClass('required');
    } else {
        $context
            .find('input[required], select[required]')
            .attr('required-disabled', 'true')
            .attr('aria-required-disabled', 'true')
            .removeAttr('required')
            .removeAttr('aria-required');
        $context
            .find('.required')
            .addClass('required-disabled')
            .removeClass('required')
            .removeClass('is-invalid');
        }
};

var updateProductListProducts = function($html) {
    // if we have a new DOM replace the old DOM
    if ($html) {
        //clean up the DOM a bit
        $html
            .find('.product-list-enhancements-modal-quote-product')
            .each(function() {
                var $this = $(this);
                var url = $this.find('a.line-item-name').attr('href');
                $this
                    .find('a.quickview')
                    .removeClass('quickview edit')
                    .attr('href', url);
            });
        $('#productListEnhancementsModalShowQuote')
            .find('.product-list-enhancements-modal-quote-products')
            .replaceWith($html);
    // if we don't have a new DOM then just empty it out
    } else {
        $('#productListEnhancementsModalShowQuote')
            .find('.product-list-enhancements-modal-quote-products')
            .empty();
    }
};

var resetAddressForm = function($context) {
    var $address = $context.find('.product-list-enhancements-modal-quote-address');
        $context.removeClass('selected');
        $address.val($address.find('option[selected]').val());
        clearAddressForm($context);
        $context.find('.address-stage-2').removeClass('open');
        $context.find('.product-list-enhancements-modal-quote-address-modify').removeClass('d-none');
        $context.find('.product-list-enhancements-modal-quote-address-change').val('none');
};

var clearAddressForm = function($context) {
    $context
        .find(`
            .product-list-enhancements-modal-quote-id,
            .product-list-enhancements-modal-quote-firstname,
            .product-list-enhancements-modal-quote-lastname,
            .product-list-enhancements-modal-quote-address1,
            .product-list-enhancements-modal-quote-address2,
            .product-list-enhancements-modal-quote-city,
            .product-list-enhancements-modal-quote-postalCode,
            .product-list-enhancements-modal-quote-phone,
            .product-list-enhancements-modal-quote-state,
            .product-list-enhancements-modal-quote-country
        `)
        .val('');
    $context
        .find('.is-invalid')
        .removeClass('is-invalid');
};

var eventStopPropagation = function(e) {
    e.stopPropagation();
};

var eventClickWindow = function(e) {
    $('#productListEnhancementsModalShowQuote').modal('toggle');
};

var eventResizeWindow = function() {
    productListEnhancementsHelpers.setModalPositionTop($('#productListEnhancementsModalShowQuote'));
};

var eventClickLearnMore = function(e) {
    e.preventDefault();
    $('#learnMoreModal').modal('show');
};

var eventClickLearnMoreFragile = function(e) {
    e.preventDefault();
    $('#fragileLearnMoreModal').modal('show');
};

var eventChangeQuantity = function(e) {
    var $this = $(this);
    var $modal = $this.parents('#productListEnhancementsModalShowQuote');

    $.ajax({
        url: $modal.data().editProductUrl,
        method: 'POST',
        data: {
            quantity: $this.val(),
            productListId: $modal.data().listId,
            productListItemId: $this.data().uuid
        },
        dataType: 'json',
        success: function (data) {
            var $card = $this.parents('.card');
            var cardData = $card.data();
            var quantity = parseInt($this.val());
            var currencyFormatter = Intl.NumberFormat(cardData.currencyLocale, {
                style: 'currency',
                currency: cardData.currencyCode,
            });
            var unitListPrice = false;
            var unitSalesPrice = false;

            if (cardData.unitListPrice) {
                unitListPrice = parseFloat(cardData.unitListPrice);
            }

            if (cardData.unitListPrice) {
                unitSalesPrice = parseFloat(cardData.unitSalesPrice);
            }

            if (cardData && unitListPrice) {
                $card
                    .find('.list-price')
                    .html(currencyFormatter.format(unitListPrice * quantity));

                $card
                    .find('.price .list .value')
                    .attr('content', unitListPrice * quantity);
            }

            if (cardData && unitSalesPrice) {
                $card
                    .find('.sales-price')
                    .html(currencyFormatter.format(unitSalesPrice * quantity));

                $card
                    .find('.price .sales .value')
                    .attr('content', unitSalesPrice * quantity);
            }

            if (data.success === true) {
                $('.product-list-enhancements-global-error-display').trigger('clear');
                $('.product-list-enhancements-card-product[data-list-item-id="' + $this.data().uuid + '"]')
                    .trigger('updateQuantity', $this.val());
            } else {
                $('.product-list-enhancements-global-error-display').trigger('error');
            }
        },
        error: function () {
            $('.product-list-enhancements-global-error-display').trigger('error');
        }
    });

    $('body').trigger('productListEnhancements:afterUpdateProductQuantity');
};

var eventChangeAssembly = function(e) {
    e.preventDefault();
    var $this = $(this);
    var $modal = $('#productListEnhancementsModalShowQuote');
    $.spinner().start();
    $.ajax({
        url: $modal.find('.modal-body').data().urlAssembly,
        type: 'POST',
        data: {
            productListId: $modal.data().listId,
            productListItemId: $this.data().uuid,
            assembly: $this.is(':checked')
        },
        dataType: 'html',
        success: function() {
            $('.product-list-enhancements-global-error-display').trigger('clear');
            $.spinner().stop();
        },
        error: function() {
            $('.product-list-enhancements-global-error-display').trigger('error');
            $.spinner().stop();
        }
    });
};

var eventShow = function(e) {
    $.spinner().start();
    var $this = $(this);
    var $quoteOptions = $this.find('.product-list-enhancements-modal-quote-option');
    $('.product-list-enhancements-detail').trigger('unselectAllProducts');
    productListEnhancementsHelpers.setModalPositionTop($this);
    $.ajax({
        url: $this.find('.modal-body').data().urlProducts,
        type: 'POST',
        data: {
            listId: $this.data().listId,
            pids: JSON.stringify($this.data().pids)
        },
        dataType: 'html',
        success: function(html) {
            $('.product-list-enhancements-global-error-display').trigger('clear');
            updateProductListProducts($(html));
            $.spinner().stop();
        },
        error: function() {
            $('.product-list-enhancements-global-error-display').trigger('error');
            $.spinner().stop();
        }
    });
    resetAddressForm($quoteOptions);
    toggleAddressFormFieldRequiredState($quoteOptions, false);
    addListEventListenersWindow();
};

var eventHidden = function(e) {
    var $quoteOptions = $('#productListEnhancementsModalShowQuote').find('.product-list-enhancements-modal-quote-option');
    $quoteOptions
        .find('[type=checkbox]')
        .prop('checked', false);
    resetAddressForm($quoteOptions);
    toggleAddressFormFieldRequiredState($quoteOptions, true);
    removeListEventListenersWindow();
    updateProductListProducts();
    $('input[name=product-list-selected-ids').val('');
};

var eventSubmit = function(e) {
    e.preventDefault();
    var $this = $(this);
    $.spinner().start();
    $.ajax({
        url: $this.data().url,
        type: 'POST',
        data: $this.serialize(),
        dataType: 'json',
        success: function(data) {
            $('.product-list-enhancements-global-error-display').trigger('clear');
            if (data && data.success === true) {
                $('.product-list-enhancements-detail').trigger('quoteSuccess');
            } else {
                $('.product-list-enhancements-detail').trigger('quoteFailure');
            }
            $.spinner().stop();
        },
        error: function(params) {
            $('.product-list-enhancements-detail').trigger('quoteFailure');
            $('.product-list-enhancements-global-error-display').trigger('error');
            $.spinner().stop();
        }
    });
};

var eventClickRemove = function(e) {
    e.preventDefault();
    var $this = $(this);
    var $modal = $('#productListEnhancementsModalShowQuote');
    var pids = $modal.data().pids;
    var pidsUpdated = pids.filter(function(item) {
        return item !== this;
    }, $this.data().pid);
    if (pidsUpdated.length === 0) {
        $modal.modal('toggle');
    } else {
        $modal.data('pids', pidsUpdated);
        $this.parents('.product-list-enhancements-modal-quote-product').remove();
    }
};

var eventChangeCheckboxAddress = function(e) {
    var $modal = $('#productListEnhancementsModalShowQuote');
    var $quoteOption = $(this).parents('.product-list-enhancements-modal-quote-option');
    var quoteOptionSelectData = $quoteOption.find('.product-list-enhancements-modal-quote-address').data();
    if ($quoteOption.hasClass('selected')) {
        resetAddressForm($quoteOption);
        toggleAddressFormFieldRequiredState($quoteOption, false);
    } else {
        //check to see if customer has any addresses, if not set form to new address
        if (quoteOptionSelectData && quoteOptionSelectData.optionsCount === 0) {
            setAddressAddState($quoteOption);
            $quoteOption
                .find('.product-list-enhancements-modal-quote-address')
                .parent()
                .hide();
        }
        $quoteOption.addClass('selected');
        $modal.animate({
            scrollTop: $quoteOption.offset().top - $modal.offset().top + $modal.scrollTop()
        });
    }
};

var eventChangeCheckboxLogo = function(e) {
    $('.product-list-enhancements-modal-quote-option-logo').toggleClass('selected');
};

var eventChangeAddress = function() {
    if ($(this).val() !== 'new') {
        var $stage1 = $(this).parents('.address-stage-1');
        clearAddressForm($stage1);
        $stage1.find('.address-stage-2').removeClass('open');
        $stage1.find('.product-list-enhancements-modal-quote-address-modify').removeClass('d-none');
        toggleAddressFormFieldRequiredState($stage1, false);
    }
};

var eventClickAddressAdd = function(e) {
    e.preventDefault();
    var $quoteOption = $(this).parents('.product-list-enhancements-modal-quote-option');
    setAddressAddState($quoteOption);
};

var eventClickAddressUpdate = function(e) {
    e.preventDefault();
    var $quoteOption = $(this).parents('.product-list-enhancements-modal-quote-option');
    var $stage1 = $quoteOption.find('.address-stage-1');
    var $stage2 = $stage1.find('.address-stage-2');
    if (!$stage2.hasClass('open')) {
        var data = $stage1.find('.product-list-enhancements-modal-quote-address option:selected').data();
        $quoteOption.find('.product-list-enhancements-modal-quote-address-change').val('update');
        $stage1.find('.product-list-enhancements-modal-quote-id').val(data.id);
        $stage1.find('.product-list-enhancements-modal-quote-firstname').val(data.firstName);
        $stage1.find('.product-list-enhancements-modal-quote-lastname').val(data.lastName);
        $stage1.find('.product-list-enhancements-modal-quote-address1').val(data.address1);
        $stage1.find('.product-list-enhancements-modal-quote-address2').val(data.address2);
        $stage1.find('.product-list-enhancements-modal-quote-city').val(data.city);
        $stage1.find('.product-list-enhancements-modal-quote-state').val(data.stateCode);
        $stage1.find('.product-list-enhancements-modal-quote-postalCode').val(data.postalCode);
        $stage1.find('.product-list-enhancements-modal-quote-country').val(data.countryCode);
        $stage1.find('.product-list-enhancements-modal-quote-phone').val(data.phone);
        $stage2.addClass('open');
        toggleAddressFormFieldRequiredState($stage1, true);
    }
};

var addListEventListenersWindow = function() {
    $(window)
        .on('resize scroll', eventResizeWindow)
        .on('click', eventClickWindow);
};

var removeListEventListenersWindow = function() {
    $(window)
        .off('resize scroll', eventResizeWindow)
        .off('click', eventClickWindow);
};

var addListEventListeners = function() {
    $('#productListEnhancementsModalShowQuote')
        .on('change', '#product-list-enhancements-modal-quote-billing, #product-list-enhancements-modal-quote-shipping', eventChangeCheckboxAddress)
        .on('change', '#product-list-enhancements-modal-quote-logo', eventChangeCheckboxLogo)
        .on('submit', '.product-list-enhancements-modal-quote-form', eventSubmit)
        .on('click', '.product-list-enhancements-modal-quote-add', eventClickAddressAdd)
        .on('click', '.product-list-enhancements-modal-quote-update', eventClickAddressUpdate)
        .on('click', '[data-target="#learnMoreModal"]', eventClickLearnMore)
        .on('click', '[data-target="#fragileLearnMoreModal"]', eventClickLearnMoreFragile)
        .on('change', '[data-option-id="productAssembly"]', eventChangeAssembly)
        .on('change', '.quantity', eventChangeQuantity)
        .on('change', '.product-list-enhancements-modal-quote-address', eventChangeAddress)
        .on('click', '.remove-product', eventClickRemove)
        .on('click', eventStopPropagation)
        .on('show.bs.modal', eventShow)
        .on('hidden.bs.modal', eventHidden);
};

var removeListEventListeners = function() {
    $('#productListEnhancementsModalShowQuote')
        .off('change', '#product-list-enhancements-modal-quote-billing, #product-list-enhancements-modal-quote-shipping', eventChangeCheckboxAddress)
        .off('change', '#product-list-enhancements-modal-quote-logo', eventChangeCheckboxLogo)
        .off('submit', '.product-list-enhancements-modal-quote-form', eventSubmit)
        .off('click', '.product-list-enhancements-modal-quote-add', eventClickAddressAdd)
        .off('click', '.product-list-enhancements-modal-quote-update', eventClickAddressUpdate)
        .off('click', '[data-target="#learnMoreModal"]', eventClickLearnMore)
        .off('click', '[data-target="#fragileLearnMoreModal"]', eventClickLearnMoreFragile)
        .off('change', '[data-option-id="productAssembly"]', eventChangeAssembly)
        .off('change', '.quantity', eventChangeQuantity)
        .off('change', '.product-list-enhancements-modal-quote-address', eventChangeAddress)
        .off('click', '.remove-product', eventClickRemove)
        .off('click', eventStopPropagation)
        .off('show.bs.modal', eventShow)
        .off('hidden.bs.modal', eventHidden);
};

var init = function() {
    addListEventListeners();
};

var destroy = function() {
    removeListEventListeners();
    removeListEventListenersWindow();
    updateProductListProducts();
    toggleAddressFormFieldRequiredState($('.product-list-enhancements-modal-quote-option-shipping, .product-list-enhancements-modal-quote-option-billing'), true);
    $('#productListEnhancementsModalShowQuote')
        .data('pids', [])
        .data('listId', '')
};

module.exports = {
    init,
    destroy
};
