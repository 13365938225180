'use strict';
var base = require('core/components/clientSideValidation');
/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
base.validateForm = function validateForm(event) {
    var valid = true;

    if (this.checkValidity && !this.checkValidity()) {
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }

        if (!this.validity.valid) {
            if ($(this).is('input, select')) {
                // handle where 'this' is a form field
                $(this).trigger('invalid', this.validity);
            } else {
                $(this).find('input, select').each(function () {
                    // handle where 'this' is a form
                    $(this).trigger('invalid', this.validity);
                });
            }
        }
    }

    return valid;
}
/**
 * Password Messaging Helper Functions
 */

var $passwordFields = $("input[type='password']");

if ($passwordFields.length) {
    var $passwordInput = $('.password-validate'),
        $value = '';

    // change the border of the input field to alert the user of acceptance|error
    function validatePassword() {
        var $passwordHelper = $(".password-validation-helper li");
        if ($passwordHelper.hasClass("error") || $passwordHelper.hasClass("empty")) {
            $passwordInput.removeClass("is-valid")
                .addClass("is-invalid");
            $passwordHelper.not(".valid")
                .addClass("error")
                .removeClass("empty");
        } else {
            $passwordInput.addClass("is-valid")
                .removeClass("is-invalid");
        }
    };

    /**
     * Display validation messages and highlight
     * each one of the requirements for the password
     * @param {string} clearClasses - which classes to clear
     */
    function checkEachRequirement(clearClasses) {
        $(".password-validation-helper li").each(function () {
            var $reg = new RegExp($(this).data("val-regex"));
            $(this).removeClass(clearClasses);
            if ($reg.test($value)) {
                $(this).addClass("valid")
                    .removeClass("error empty");
            } else if (!$reg.test($value) && $passwordInput.focus) {
                $(this).addClass("empty")
                    .removeClass("valid");
            } else {
                $(this).addClass("error")
                    .removeClass("valid empty");
            }
        });
    };

    //show password checkbox
    $("[id^=show-password]").on('click', function () {
        var $label = $('label[for="' + $(this).attr('id') + '"]');

        if ($(this).prop('checked')) {
            $(this).closest('.password-group').find($passwordFields).attr("type", "text");
            $label.text('hide');
        } else {
            $(this).closest('.password-group').find($passwordFields).attr("type", "password");
            $label.text('show');

        }
    });

    $passwordInput.on("keyup", function () {
        $value = $(this).val();

        // first check if the form is in error state, then re-eval
        if ($(this).hasClass("is-invalid")) {
            // leave the "error" states until valid
            checkEachRequirement("valid empty");
            validatePassword();
        } else {
            checkEachRequirement("error valid empty");
        };

        // change input decoration when user leaves
        $(this).on("blur", validatePassword);
    });
}


function validateFormFix(form, event) {
    base.validateForm.call(form, event || null);
}

base.fileInputInvalid = () => {
    $('form input[type=file]').on('invalid', function (e) {
        if (!this.validity.valid) {
            $('.invalid-feedback').show()
        }
    });
};

base.invalid = () => {
    $('form input, form select').on('invalid', function (e) {
        e.preventDefault();
        this.setCustomValidity('');
        if (!this.validity.valid) {

            var validationMessage = this.validationMessage;
            $(this).addClass('is-invalid');
            if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                validationMessage = $(this).data('pattern-mismatch');
            }
            if ((this.validity.rangeOverflow || this.validity.rangeUnderflow) &&
                $(this).data('range-error')) {
                validationMessage = $(this).data('range-error');
            }
            if ((this.validity.tooLong || this.validity.tooShort) &&
                $(this).data('range-error')) {
                validationMessage = $(this).data('range-error');
            }
            if (this.validity.valueMissing && $(this).data('missing-error')) {
                validationMessage = $(this).data('missing-error');
            }
            if (this.validity.typeMismatch && this.type === 'email' && $(this).data('type-mismatch')) {
                validationMessage = $(this).data('type-mismatch');
            }
            $(this).parents('.form-group').find('.invalid-feedback')
                .text(validationMessage);
        }
    });
};

base.clearPasswordErrors = () => {
    $(document).on('click', 'form input[type="password"].is-invalid, input[type="text"].is-invalid', function (e) {
        $(this).removeClass('is-invalid').find('.invalid-feedback').hide()
    });
};

base.functions.validateForm = validateFormFix;
module.exports = base;
