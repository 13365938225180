'use strict';

// Adds style to label so that is is positioned over its corresponding select element
// Update the textContent of the Label to include the value of the select element
function setupSelectOverLabel(label) {
    // get corresponding select via 'for' attribute
    var labelAtt = label.getAttribute('data-label-over-select'),
        selectId = label.getAttribute('for'),
        labelOptions = null,
        labelName = null,
        select = null;

    if (selectId && labelAtt) {
        labelOptions = JSON.parse(labelAtt);
        // get select value
        select = label.parentElement.querySelector('#' + selectId);

        // check for PDP and that label is quantity, if so then use the 'pdpName'
        if ($(label).closest('.product-quickview').length === 0 && selectId.includes('quantity')) {
            if(labelOptions.pdpShortName !== undefined && window.innerWidth < 768)
                labelName = labelOptions.pdpShortName.toUpperCase();
            else
                labelName = labelOptions.pdpName.toUpperCase();

            label.textContent = labelName + ': ' + select.children[select.selectedIndex].textContent;
        } else {
            // otherwise use 'name'
            if(select.children[select.selectedIndex].textContent === select.children[0].textContent && selectId.includes('size')) // Remove repetition of label
                label.textContent = select.children[select.selectedIndex].textContent;
            else
                label.textContent = labelOptions.name.toUpperCase() + ": " + select.children[select.selectedIndex].textContent;
        }
        // remove select element default bg Image
        select.style.backgroundImage = 'none';
    }
}

function labelsInit() {
    var labels = document.querySelectorAll('[data-label-over-select]');

    if (labels.length > 0) {
        [].forEach.call(labels, function(label) {
            setupSelectOverLabel(label);
        });
    }

    return true;
}


module.exports = {
    init: function init() {
        // call on load
        labelsInit();
        // call on QV ready and product attribute select
        $('body')
            .on('quickview:ready', labelsInit)
            .on('product:afterAttributeSelect', labelsInit)
            .on('productListEnhancements:afterUpdateProductQuantity', labelsInit);
    }
}
