'use strict';
/**
 * footerBackToTop
 *
 * This file shows/hides back to top button based on how far page is scrolled.
 * Uses the custom event 'scrollUpdate' dispatched from scroll.js
 */

var SiteConstants = require('constants/SiteConstants');
// back to to button
var backToTop = document.querySelector('[data-back-to-top]');
// back to top threshold for showing/hiding back to top button - 300px on mobile, 400px on desktop
var backToTopThreshold = window.innerWidth > SiteConstants.md ? 400 : 300;

function checkScrollObj(e) {
    var detail = e.detail;

    if (detail.last >= backToTopThreshold && !backToTop.classList.contains('show')) {
        backToTop.classList.add('show');
        if ( $('[data-action=Search-Show]').length && $('#footercontent.collapsible').length ) { // Show Sticky Footer
            $('#footercontent.collapsible').fadeIn();
        }
    } else if (detail.last < backToTopThreshold && backToTop.classList.contains('show')) {
        backToTop.classList.remove('show');
        if ( $('[data-action=Search-Show]').length && $('#footercontent.collapsible').length ) { // Hide Sticky Footer
            $('#footercontent.collapsible').fadeOut();
        }
    } else {
        return false;
    }
}

function init() {
    if (backToTop) {
        // add function to custom event scroll update
        window.addEventListener('scrollUpdate', function(e) {
            checkScrollObj(e);
        });
    }
}

module.exports = init;
