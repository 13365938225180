'use strict';

/**
 * update window product list experience data
 * @param {string} data - data returned from the server's ajax call
 */
 var updateUncachedData = function(data) {
    var productListEnhancementsPIDs = new Set(window.UncachedData.productListEnhancementsPIDs);
    var updatedPid;
    if (typeof data.pid === 'number') {
        updatedPid = data.pid.toString();
    } else {
        updatedPid = data.pid;
    }
    if (data.actionType === 'add') {
        productListEnhancementsPIDs.add(updatedPid);

        try {
            addToWishlist(updatedPid);
        } catch (e) {
            console.log(e);
        }

    } else if (data.actionType === 'remove') {
        productListEnhancementsPIDs.delete(updatedPid);
    }
    //TODO RVW Array.from is ES6 (not IE11 supported, does this polyfill with webpack?)
    window.UncachedData.productListEnhancementsPIDs = Array.from(productListEnhancementsPIDs);
};

/**
 * update clicked links attributes and text
 * @param {Object} specificLinks - jQuery object representing selection of product list experience links
 */
var updateLinkData = function(specificLinks, data) {
    if (!window || !window.UncachedData || !window.UncachedData.productListEnhancementsPIDs) {
        return;
    }
    var productListEnhancementsLinks = specificLinks || $('[data-productlistenhancementspid]');
    if (!productListEnhancementsLinks || !productListEnhancementsLinks.length) {
        return;
    }
    var productListEnhancementsPIDs = new Set(window.UncachedData.productListEnhancementsPIDs);
    productListEnhancementsLinks.each((_i, productListEnhancementsLink) => {
        var $productListEnhancementsLink = $(productListEnhancementsLink);
        var productlistenhancementspid = $productListEnhancementsLink.attr('data-productlistenhancementspid');
        if (productlistenhancementspid) {
            var isInProductListEnhancements = (productListEnhancementsPIDs.has(productlistenhancementspid));
            $productListEnhancementsLink.attr('data-inproductlistenhancements', isInProductListEnhancements);
            if (isInProductListEnhancements) {
                $productListEnhancementsLink.attr('title', $productListEnhancementsLink.attr('data-removemessage'));
                if ($productListEnhancementsLink.data('includetext') === true) {
                    $productListEnhancementsLink.find('span').html($productListEnhancementsLink.attr('data-removemessage'));
                }
            } else {
                $productListEnhancementsLink.attr('title', $productListEnhancementsLink.attr('data-addmessage'));
                if ($productListEnhancementsLink.data('includeicon') === true) {
                    $productListEnhancementsLink.find('span').html($productListEnhancementsLink.attr('data-addmessage'));
                }
            }
            var icon = $productListEnhancementsLink.find('.product-list-enhancements-icon');
            if (icon && icon.length) {
                $(icon).toggleClass('selected', isInProductListEnhancements);
            }
            if (data && data.productListQuantities) {
                $productListEnhancementsLink
                    .attr('data-productlistenhancementsquantity', data.productListQuantities)
                    .data('productlistenhancementsquantity', data.productListQuantities);
            } else {
                $productListEnhancementsLink
                    .attr('data-productlistenhancementsquantity', 1)
                    .data('productlistenhancementsquantity', 1);
            }
        }
    });
};

var openToast = (data) => {
    $('#productListEnhancementsPDPToast').trigger('show', function($toast) {
        if (data.productListName) {
            $toast.find('.project-name').html(data.productListName);
        } else {
            $toast.find('.project-name').html($toast.data().defaultProductListName);
        }

        $toast
            .find('.message .manage')
            .attr('href', data.productListUrl);

        if (data.manageLabel) {
            $toast.find('.message .manage').html(data.manageLabel);
        } else {
            $toast.find('.message .manage').html($toast.data().defaultManageLabelWishlist);
        }

        $toast
            .attr({
                'data-product-id': data.pid,
                'data-product-list-id': data.productListId,
            })
            .data('product-id', data.pid)
            .data('product-list-id', data.productListId);

        if (data.productListQuantities) {
            $toast
                .attr('data-product-list-quantities', data.productListQuantities)
                .data('product-list-quantities', data.productListQuantities);
        }

        if (data.productListActionType === 'add') {
            $toast.find('.message.remove, .message.both').addClass('d-none');
            $toast.find('.message.add').removeClass('d-none');
        } else if (data.productListActionType === 'remove') {
            $toast.find('.message.remove').removeClass('d-none');
            $toast.find('.message.add, .message.both').addClass('d-none');
        } else {
            $toast.find('.message.both').removeClass('d-none');
            $toast.find('.message.add, .message.remove').addClass('d-none');
        }
    });
};

var setModalPositionTop = function($modal) {
    // calculate the height of the header
    var modalTopPosition = ($('header .header').outerHeight() + parseInt($('.header-nav').css('border-bottom-width')));
    // if the header ins in the fixed position we also need to add in the header banner height
    if (!$('.header-nav').hasClass('fixed')) {
        modalTopPosition = (modalTopPosition + $('.header-banner').outerHeight());
    }
    // set the modal top position
    $modal.css({
        top: modalTopPosition
    });
};

module.exports = {
    openToast,
    setModalPositionTop,
    updateLinkData,
    updateUncachedData
};
