'use strict';

/**
 * blogToggles - Activates triggers to add a active class to a target element
 * @param {*} toggle - Button that triggers changing class on a target element 
 */

function setupBlogToggle(toggle) {
    var blogTarget = toggle.getAttribute('data-blog-toggle');

    $(toggle).toggleClass('active');
    $(blogTarget).toggleClass('active');
}

function initBlogToggles() {
    var blogToggles = document.querySelectorAll('[data-blog-toggle]');

    [].forEach.call(blogToggles, function(toggle) {
        toggle.addEventListener('click', function() {
            setupBlogToggle(toggle);
        });
    });
}

module.exports = {
    init: () => {
        initBlogToggles();
    }
};